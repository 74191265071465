import {get, post} from 'ys-admin'

/**
 * 获取任务详情
 * @param {Object} params
 * @returns {Object}
 */
export const eventInfo = function (params) {
  return get('/event/Info', params)
};

/**
 * 获取任务详情
 * @param {Object} params
 * @returns {Object}
 */
export const getEventMembers = function (params) {
  return get('/event/member/List', params)
};
/**
 * 发布任务
 * @param {Object} params
 * @returns {Object}
 */
 export const eventPublish = function (params) {
  return post('/event/Publish', params)
};

/**
 *  完成任务
 * @param {Object} params
 * @returns {Object}
 */
 export const eventFinish = function (params) {
  return post('/event/Finish', params)
};
