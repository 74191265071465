<template>
  <div class="home">
    <ys-page-list
        ref="table"
        :tableHeight="height-260"
        @on-selection-change="checkItem"
        :params="params"
        :footer="false"
        :multiColumn="false"
        action="/standard/dictReason/List"
        :header="false"
        :isEmptySelect="isEmptySelect"
        :headerColumns="headerColumns">
    </ys-page-list>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok">确定</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js';
import components from '@/mixins/components.js';

export default {
  name: 'quickInfo',
  mixins: [pageList, components],
  props: {
    orgId: {
      type: [String, Number],
      default: ''
    },
    itemId: {
      type: [String, Number],
      default: ''
    },
  },
  components: {},
  data() {
    return {
      headerColumns: [
        {width: 50, type: 'selection', title: '选择框', align: 'center'},
        {minWidth: 500, title: '风险依据内容', key: 'desc', align: 'left', },
      ],
      dataList: [],
      params: {
        dictItemId: ''
      }
    }
  },
  mounted() {
    this.params.dictItemId=this.itemId;
    this.getList()
  },
  methods: {
  }

}
</script>
