<template>
  <div :id="'column'+type" class="column" style="width: 650px"></div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: 'pie',
  props: {
    commentList: {type: Array, default: () => []},
    categoryList: {type: Array, default: () => []},
    type: {type: Number, default: 5},
  },
  data() {
    return {
      option: {
        title: {
          left: 'center',
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: 'transparent'
            }
          }
        },
        grid: {
          left: '0%',
          right: '0%',
          bottom: '0%',
          top: '15%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            axisLabel: {
              interval: 0,
              rotate: 40,
            },
            data: []
          }
        ],
        yAxis: [
          {
            type: 'value',
            minInterval: 1
          }
        ],
        series: [
          {
            name: '已完成',
            type: 'bar',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          }
        ]
      }
    }
  },

  mounted() {
    let chartDom = document.getElementById('column' + this.type);
    let myChart = echarts.init(chartDom);
    (this.categoryList || []).map((categoryItem, i) => {
      this.option.xAxis[0].data.push(categoryItem.iname);
      (this.commentList || []).filter((i) => i.riskGrade === this.type).map((item) => {
        if (item.categoryId === categoryItem.id) {
          this.option.series[0].data[i] = this.option.series[0].data[i] + 1;
        }
      })
    })
    this.option.series[0].name = this.$getBaseDataName('风险等级', this.type, false);
    this.option.title.text = this.$getBaseDataName('风险等级', this.type, false);
    myChart.setOption(this.option);
  }
}
</script>
<style lang="less" scoped>
.column {
  height: 300px;
  width: 650px !important;
  text-align: center;
  margin-top: 20px;
  border: 1px solid #ececec;
  padding: 10px;
}
</style>
