<template>
  <div class="home">
    <ys-page-list
        ref="table"
        keyLabel="policyId"
        :tableLoading="tableLoading"
        :tableHeight="tableHeight"
        :headerColumns="headerColumns"
        :multiColumn="false"
        :multiBtn="multiBtn"
        :tableData="policyList"
        :searchFun="getList"
        :isPage="false"
        @on-selection-change="checkItem">
    </ys-page-list>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js';
import {spotGetInfo} from "@/api/spotCheckTask";

export default {
  name: '',
  mixins: [pageList],
  props: {},
  components: {},
  data() {
    return {
      tableLoading: false,
      policyList: [],
      headerColumns: [
        {width: 50, type: 'selection', title: '选择框', align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {minWidth: 200, title: '保单号', key: 'policyNumber', align: 'center', renderConfig: {type: 'policyNoDesensitise'}},
        {minWidth: 200, title: '项目名称', key: 'projectName', align: 'center'},
      ]
    }
  },
  computed: {
    multiBtn() {
      return []
    }
  },
  watch: {},
  mounted() {
    if(this.mid) {
      this.getList()
    }
  },
  methods: {
    getList() {
      this.policyList = []
      this.tableLoading = true;
      spotGetInfo({id: this.mid, random: new Date().getTime()}).then(res => {
        this.tableLoading = false
        this.policyList = [res.data]
      }).catch(() => {
        this.tableLoading = false
      })
    },
  }

}
</script>
