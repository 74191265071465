<template>
  <div>
    <Form ref="form" class="editForm " :model="form" :rules="ruleValidate" :label-width="80" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <FormItem label="机构名称" prop="iname" style="width: 100%;" class="ys-business" v-if="!form.id">
        <ys-business :source="source" @on-clear="clearBusiness" @on-select="selectOrg"></ys-business>
        <Input v-model="form.iname" placeholder="机构名称" style="display: none"/>
      </FormItem>
      <FormItem label="信用代码" prop="societyCode" v-if="!form.id">
        <Input v-model="form.societyCode" disabled placeholder="社会信用代码"/>
      </FormItem>
      <FormItem label="法人" prop="operName" v-if="!form.id">
        <Input v-model="form.operName" disabled placeholder="法人"></Input>
      </FormItem>
      <FormItem label="状态" prop="regStatus" v-if="!form.id">
        <Input v-model="form.regStatus" disabled placeholder="状态"></Input>
      </FormItem>
      <FormItem label="机构类型" prop="type">
        <Select v-model="form.oType" :disabled="oType!==null" style="width: 50%" placeholder="组织类型" @on-change="oTypeSelect" transfer>
          <Option v-for="(item,index) in baseData['组织类型']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
        <Select v-model="form.type" transfer placeholder="机构类型" style="width: 50%">
          <Option v-for="(item,index) in orgBaseData" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="联系人" prop="phone">
        <Input v-model="form.contacts" placeholder="联系人" style="width: 40%"></Input>
        <Input v-model="form.phone" autocomplete="off" placeholder="联系人电话" style="width: 60%"></Input>
      </FormItem>

    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="save">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
// import {getProjectOrgInfo} from "@/api/projects";
import {generateSocialCreditCode} from "ys-admin";

export default {
  name: 'projectEdit',
  mixins: [edit],
  props: {
    source: {type: [Number], default: 2},
    type: {type: Number, default: null},
    oType: {type: Number, default: null},
  },
  data() {
    return {
      ruleValidate: {
        iname: [
          {required: true, message: '请填公司名称', trigger: 'change'},
          {required: true, message: '请填公司名称', trigger: 'blur'},
        ],
      },
      detail: {},
      form: {
        projectId: '',
        id: '',
        orgId: '',
        iname: '',
        societyCode: '',
        oType: 1,
        type: 1,
        uuid: '',
        shortName: '',
        operName: '',
        startDate: '',
        regStatus: '',
        contacts: '',
        phone: '',
      },
    }
  },
  computed: {
    orgBaseData() {
      let baseData = [];
      switch (this.form.oType) {
        case 1:
          baseData = this.baseData['保险公司类型'];
          break;
        case 2:
          baseData = this.baseData['职能部门类型'];
          break;
        case 3:
          baseData = this.baseData['服务机构类型'];
          break;
        case 4:
          baseData = this.baseData['参建单位类型'];
          break;
      }
      return baseData;
    }
  },
  created() {
    if (this.type) this.form.type = this.type;
    if (this.oType) {
      this.form.oType = this.oType;
      if (!this.type) this.oTypeSelect();
    }
  },
  mounted() {
    if (this.midData.length === 1) { // 编辑
      Object.keys(this.form).forEach((item) => {
        this.form[item] = this.midData[0][item]
      })
    }
  },
  methods: {
    oTypeSelect() {
      if (this.midData.length !== 1) {
        this.form.type = this.orgBaseData.length > 0 ? this.orgBaseData[0].value : '';
      }
    },
    async save() {
      let flag = true;
      if (!this.form.orgId) {
        await this.$post('/organization/AddOrEdit', this.form).then(res => {
          if (res.code !== 200) {
            flag = false;
          } else {
            this.form.orgId = res.data.id;
          }
        });
      }
      if (flag) {
        this.ok(this.form.id ? '/orgProject/Edit' : '/orgProject/Add');
      }
    },
    // 初始话数据
    clearBusiness() {
      this.$refs['form'].resetFields();
      this.form.id = ''
      this.form.orgId = ''
      this.form.projectId = ''
      this.form.iname = ''
      this.form.uuid = ''
    },
    selectOrg(data) {
      this.form.orgId = data.id;
      this.form.iname = data.iname;
      this.form.societyCode = data.societyCode;
      this.form.uuid = data.uuid;
      this.form.operName = data.operName;
      this.form.startDate = data.startDate;
      this.form.regStatus = data.regStatus || data.status;
      this.form.contacts = data.contacts;
      this.form.phone = data.phone;
      this.form.projectId = this.parentID;

      if (!data.societyCode) {
        this.$Notice.warning({
          title: "提示！",
          desc: '该机构处于非正常经营状态【' + data.regStatus + '】，已无社会统一代码，系统将自动生成此代码！'
        });
        this.form.societyCode = generateSocialCreditCode();
      }
    },
  }
}
</script>

<style lang="less" scoped>

</style>
