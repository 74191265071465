<template>
  <div :style="{maxHeight:baseInfoHeight+'px'}" style="overflow-y: auto">
    <ys-cell-group>
      <ys-cell title="项目名称" :label="detail.iname" block/>
      <ys-cell title="施工许可证号" :label="detail.licenseNum"/>
      <ys-cell title="安监号" :label="detail.code"/>
      <ys-cell title="项目所在地" :label="detail.address"/>
      <ys-cell title="所在地区" :label="(detail.countyCode||detail.cityCode||detail.provinceCode)|getRegionByCode"/>
      <ys-cell title="高度" :label="detail.buildHeight|fixed(11,2)"/>
      <ys-cell title="最高楼层" :label="detail.floorHeight"/>
      <ys-cell title="项目类别" :label="detail.category|getBaseDataName('项目类型')"/>
      <ys-cell title="形象进度" :label="detail.progress|getBaseDataName('施工阶段')"/>
      <!-- <ys-cell title="计划开工日期" :label="detail.startPlan|formatDate"/>
      <ys-cell title="计划竣工日期" :label="detail.endPlan|formatDate"/> -->
      <ys-cell title="开工日期" :label="detail.startReal|formatDate"/>
      <ys-cell title="竣工日期" :label="detail.endReal|formatDate"/>
      <ys-cell title="建筑面积" :label="detail.buildArea|fixed(2,2)"/>
      <ys-cell title="项目负责人" :label="detail.principalName"/>
      <ys-cell title="负责人电话" :label="detail.principalPhone|phoneDesensitise"/>
      <ys-cell title="安责险联络员姓名" :label="detail.safetyOfficerName"/>
      <ys-cell title="安责险联络员电话" :label="detail.safetyOfficerPhone|phoneDesensitise"/>
      <ys-cell title="合同造价" :label="detail.contractPrice|fixed"/>
      <ys-cell title="工程造价" :label="detail.amount|fixed"/>
      <ys-cell title="总面积" :label="detail.buildArea|fixed(2,2)"/>
      <ys-cell title="总长度" :label="detail.buildLength|fixed(11,2)"/>
      <ys-cell title="经纬度" :label="detail.lat+','+detail.lng"/>
      <ys-cell title="项目简介" style="width: 100%" :label="detail.description" block/>
    </ys-cell-group>
    <ys-cell-group title="施工许可证照片">
      <div v-viewer="{url: 'data-src'}">
        <ys-image
          :width="100"
          :height="100"
          :src="config.baseURL + '/resource/GetFile/' + src.id + '?size=100'"
          :data-src="config.baseURL + '/resource/GetFile/' + src.id"
          v-for="src in detail.licenseFileList"
          :key="src.id"
        ></ys-image>
      </div>
    </ys-cell-group>
    <ys-cell-group title="平面图">
      <div v-viewer="{url: 'data-src'}">
        <ys-image
          :width="100"
          :height="100"
          :src="config.baseURL + '/resource/GetFile/' + src.id + '?size=100'"
          :data-src="config.baseURL + '/resource/GetFile/' + src.id"
          v-for="src in detail.floorViewFileList"
          :key="src.id"
        ></ys-image>
      </div>
    </ys-cell-group>
    <ys-cell-group title="鸟瞰图">
      <div v-viewer="{url: 'data-src'}">
        <ys-image
          :width="100"
          :height="100"
          :src="config.baseURL + '/resource/GetFile/' + src.id + '?size=100'"
          :data-src="config.baseURL + '/resource/GetFile/' + src.id"
          v-for="src in detail.aerialViewFileList"
          :key="src.id"
        ></ys-image>
      </div>
    </ys-cell-group>
  </div>
</template>

<script>
import {projectInfo} from "@/api/projects";
import baseInfo from "@/mixins/baseInfo";
import {mapGetters} from 'vuex'

export default {
  name: "baseInfo",
  mixins: [baseInfo],
  props:{
    mid:{type:[String,Number],default:null}
  },
  data() {
    return {
      detail: {},
    }
  },
  computed: {
    ...mapGetters(['config']),
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      projectInfo({id: this.mid,t:new Date().getTime()}).then(res => {
        this.detail = res.data;
      })
    }
  }
}
</script>

<style scoped>

</style>