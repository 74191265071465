<template>
  <task :type="6" keyID="CFE99D4552E117C5B7A0B01E3B56064C"></task>
</template>

<script>
import Task from "@/views/task/index.vue";

export default {
  name: 'emergency',
  components: {Task},
  data() {
    return {}
  },
  mounted() {
  },
  methods: {}
}
</script>
