<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="60" :style="{height:height-200+'px',overflowY:'auto'}">
      <div v-for="(item,index) in form.dictList" :key="item.id">
        <FormItem label="验收项" prop="name" style="width: 100%">
          <Input disabled v-model="item.name" style="width:calc(90% - 60px)"></Input>
          <Button @click="down(index)">下载验收表</Button>
        </FormItem>
        <FormItem label="附件" :prop="'dictList.' + index + '.fileIds'" :rules="{required: true,type:'array', message: '请上传附件', trigger: 'change'}" style="width: 100%">
          <ys-upload :headers="{token:token}" action="/web/resource/UploadFile" :defaultList.sync="item.fileList" type="halfList" @on-success="bLFile($event,index)" :limit="0"></ys-upload>
        </FormItem>
      </div>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok('/inspects/Finish')">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit.js'
import {inspectsInfo, inspectsItemEdit} from "@/api/inspects";

export default {
  name: 'doTask',
  mixins: [edit],
  data() {
    return {
      form: {
        id: '',
        dictList: [],
      },
    }
  },
  computed: {},
  created() {
  },
  mounted() {
    this.form.id = this.mid;
    inspectsInfo({id: this.mid}).then((res) => {
      if (res.code === 200) {
        this.form.dictList = (res.data.itemList || []).map(item => {
          item.fileList = item.fileList || [];
          item.fileIds = (item.fileList || []).map(item => item.id);
          return item;
        });
      }
    })
  },
  methods: {
    down(index) {
      window.location.href = this.form.dictList[index].url;
    },
    bLFile(data, index) {
      this.form.dictList[index].fileIds = data.map((item) => {
        return item.id
      });
      inspectsItemEdit(this.form.dictList[index]).catch(() => {
        this.form.dictList[index].fileIds = [];
      })
    }
  }
}
</script>
<style scoped>

</style>
