<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" :label-width="100" inline="">
      <FormItem label="验收项目" icon="ios-create" prop="policyId">
        <Input v-model="form.projectName" disabled placeholder="验收项目"></Input>
      </FormItem>

      <FormItem label="服务计划" prop="policyPlanId">
        <Select v-model="form.policyPlanId" clearable placeholder="服务计划" transfer style="width: 100%;" @on-change="planChange" @on-clear="planClear">
          <Option v-for="(item, index) in subPlanList" :value="item.id" :key="item.id" :disabled="item.checkId > 0 && item.checkId !== form.id">[{{ item.checkId > 0 ? '已关联' : '未关联' }}]{{ item.title || `服务计划明细${index + 1}` }}</Option>
        </Select>
      </FormItem>

      <FormItem label="名称" prop="iname">
        <Input v-model="form.iname" placeholder="名称" @on-change="inameChange"></Input>
      </FormItem>
      <FormItem label="验收时间段" prop="serveTime">
        <ys-date-picker style="width: 100%" type="daterange" placeholder="验收时间段" v-model="form.serveTime"/>
      </FormItem>
      <FormItem label="验收内容" prop="dictList" style="width: 100%">
        <CheckboxGroup v-model="form.dictList">
          <Checkbox v-for="(item) in categoryList" class="checkbox-border-item" border :label="item.id" :key="item.id"> {{ item.name }}</Checkbox>
        </CheckboxGroup>
      </FormItem>

      <FormItem label="验收专家" prop="expertList" style="width: 100%">
        <Input v-model="form.expertName" disabled placeholder="验收专家">
        </Input>
      </FormItem>
      <FormItem label="备注" prop="desc" style="width: 100%">
        <Input v-model="form.desc" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows: 3,maxRows: 5}" placeholder="输入备注"></Input>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <!-- <Button type="primary" @click="save('onlySave')">确定并保存</Button> -->
      <Button type="primary" @click="save('saveAndRelease')">保存并发布</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit.js'
import {inspectsInfo, getInspectsList} from "@/api/inspects.js";
import {subPlanList} from "@/api/plan";
import {publishInspects} from "@/api/inspects.js";
import {strReword} from "ys-admin";

export default {
  name: '',
  mixins: [edit],
  components: {},
  props: {
    type: {type: Number, default: 5},
  },
  data() {
    return {
      ruleValidate: {
        iname: [
          {type: 'string', required: true, message: '请填写名称', trigger: 'blur'}
        ],
        serveTime: [
          {type: 'array', required: true, message: '验收时间段不能为空！', trigger: 'change'}
        ],
        dictList: [
          {type: 'array', required: true, message: '请选择验收内容！', trigger: 'change'}
        ],
        expertList: [
          {type: 'array', required: true, message: '请选择验收专家！', trigger: 'change'}
        ],
        policyId: [
          {type: 'number', required: true, message: '验收项目不能为空！', trigger: 'change'}
        ],
      },
      categoryList: [],
      subPlanList: [], // 计划明细(子计划)列表
      form: {
        iname: '',
        code: '',
        policyId: '',
        projectName: '',
        id: '',
        desc: '',
        startAt: '',
        endAt: '',
        serveTime: [],
        expertName: '',
        expertList: [],
        dictList: [],
        servePlanId: '',
        policyPlanId: ''
      }
    }
  },
  created() {},
  async mounted() {
    this.modal.initLoading = true;
    await getInspectsList({limit: -1}).then(res => {
      if (res.code === 200) {
        this.categoryList = res.data.list || [];
      }
    });
    if (this.mid) {
      await inspectsInfo({id: this.mid}).then(res => {
        Object.keys(this.form).forEach((item) => {
          this.form[item] = res.data[item]
        })
        this.form.serveTime = [res.data.startAt, res.data.endAt]
        this.form.dictList = (res.data.itemList || []).map(item => item.inspectDictId);
        this.form.expertList = (res.data.expertList || []).map(item => item.expertId);
        this.form.expertName = (res.data.expertList || []).map(item => item.name).join(',');
      })
      this.getSubPlanList()
    } else { // 新增
      this.form.serveTime = [new Date(), new Date(new Date().getTime() + 7 * 3600 * 1000 * 24)]
      if (this.midData.length === 1) {
        this.form.type = 5;
        this.form.projectName = this.midData[0].projectName
        this.form.servePlanId = this.midData[0].servePlanId
        this.form.policyPlanId = this.midData[0].id
        this.form.policyId = this.midData[0].policyId
        await this.getSubPlanList();
      }
    }
    this.modal.initLoading = false;
  },
  computed: {},
  methods: {
    // 服务计划明细列表
    async getSubPlanList() {
      await subPlanList({policyId: this.form.policyId, limit: -1, type: this.type || 5}).then(res => {
        this.subPlanList = (res.data.list || [])
        if (this.subPlanList.length > 0) {
          // 默认选中第一个没有关联任务的计划
          const subPlan = this.subPlanList.filter(item => item.checkId === 0)
          this.form.policyPlanId = !this.form.policyPlanId && this.form.policyPlanId !== 0 ? (subPlan.length > 0 ? subPlan[0].id : this.subPlanList[0].id) : this.form.policyPlanId
          this.form.servePlanId = !this.form.servePlanId ? (subPlan.length > 0 ? subPlan[0].servePlanId : this.subPlanList[0].servePlanId) : this.form.servePlanId
          this.planChange();
        }
      })
    },
    // 切换服务计划
    planChange(event) {
      const planInfo = (this.subPlanList || []).find(plan => this.form.policyPlanId > 0 && plan.id === this.form.policyPlanId);
      if (!planInfo) return;
      this.form.policyOrgId = planInfo.orgId;
      this.form.servePlanId = planInfo.servePlanId;
      this.form.serveTime = [planInfo.startAt || new Date(), planInfo.finishAt || new Date(new Date().getTime() + 7 * 3600 * 1000 * 24)];
      const expertList = planInfo.expertList || []
      // 组长
      expertList.some(item => item.expertId === planInfo.leaderId) || expertList.push({expertId: planInfo.leaderId, iname: planInfo.leaderName})
      // 非编辑或者是在页面中切换服务计划时再使用计划中携带的专家
      if(!this.mid || event) {
        this.form.expertList = []
        this.form.expertName = []
        this.setExperts(expertList.reverse())
      }
    },
    // 清空已选中服务计划,重置服务时间、截止时间、服务计划id以及服务子计划id
    planClear() {
      this.form.serveTime = [new Date(), new Date(new Date().getTime() + 7 * 3600 * 1000 * 24)]
      this.form.servePlanId = ''
      this.form.policyPlanId = ''
    },
    setExperts(data) {
      const list = []
      data.forEach((item) => {
        if (!this.form.expertList.some(expertId => expertId === item.id || expertId === item.expertId)) {
          list.push(item)
        }
      });
      this.form.expertList = list.map(item => item.expertId);
      this.form.expertName = list.map(item => item.iname).join(',');
    },
    getData(data) {
      this.form.policyId = data[0].id;
      this.form.projectName = data[0].projectName;
      this.form.expertList = []
      this.form.servePlanId = ''
      this.form.policyPlanId = ''
      this.getSubPlanList();
    },
    inameChange() {
      this.$nextTick(() => {
        this.form.iname = strReword(this.form.iname)
      })
    },
    publish(data) {
      publishInspects({id: data.id}).then(res => {
        if (res.code === 200) {
          this.close();
          this.$Notice.success({
            title: "提示！",
            desc: '操作成功！'
          });
          this.$emit('on-ok', data);
        } else {
          this.modal.spinShow = false;
        }
      }).catch(() => {
        this.modal.spinShow = false;
      })
    },
    save(mode) {
      this.form.startAt = this.form.serveTime[0];
      this.form.endAt = this.form.serveTime[1];
      this.modal.spinShow = true;
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$post('inspects/AddOrEdit', this.form).then((res) => {
            if (res.code === 200) {
              if (mode === 'saveAndRelease') {
                this.publish(res.data)
              } else {
                this.close();
                this.$Notice.success({
                  title: "提示！",
                  desc: '操作成功！'
                });
                this.$emit('on-ok', res.data);
              }

            }
          }).catch(() => {
            this.modal.spinShow = false;
          })
        } else {
          this.$Notice.error({
            title: "操作失败！",
            desc: '请检查表单是否填项正确！'
          });
          this.modal.spinShow = false;
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.checkbox-border-item {
  margin-bottom: 10px;
  padding: 0 10px;
}

</style>
