<template>
  <div class="home">
    <ys-page-list
        ref="table"
        :multiBtn="multiBtn"
        showContextMenu
        keyID="22A28D76081D2AA52E6A1AC4F418C58C"
        :params="params"
        action="/permission/role/List"
        :multiColumn="true"
        :is-page="false"
        :tableHeight="tableHeight"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add">新增角色</Button>
        </ButtonGroup>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="800" title="新增/修改角色">
      <Edit :mid="detailID" @on-ok="getList"></Edit>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import Edit from "@/views/role/edit.vue";

export default {
  name: 'projects',
  mixins: [pageList],
  components: {Edit},
  data() {
    return {
      params: {
        keyword: '',
      },
      putInFlag: false,
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {width: 130, title: '角色名称', key: 'iname', align: 'left',},
        {minWidth: 170, title: '创建时间', key: 'createdAt', align: 'left'},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0]?.orgId == null || this.tableSelectItem[0]?.orgId == 0
            },
            {
              click: () => this.delItem('/permission/role/Remove'),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0]?.orgId == null || this.tableSelectItem[0]?.orgId == 0
            },
          ]
        },
      ]
    },
  },
  mounted() {
    this.getList();
  },
  methods: {}
}
</script>
