<template>
  <div>
    <ys-page-list
      keyID="E6920C5F3C3BF968A1F8B710E7D58F46"
      ref="table"
      action="check/List"
      :params="params"
      :tableHeight="height-300"
      :headerColumns="headerColumns"
      @on-selection-change="checkItem"
      :isEmptySelect="isEmptySelect"
    >
      <template slot="search">
        <FormItem label="任务类型">
          <Select v-model="params.type" :disabled="!!type" placeholder="检查单类型" style="width: 120px;">
            <Option v-for="(item,index) in checkType" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="服务子类型" prop="subType" v-if="subTypeList.length > 0" show>
          <Select v-model="params.subType" :disabled="!!subType" clearable placeholder="服务子类型" style="width: 120px;">
            <Option v-for="(item,index) in subTypeList" :value="item.value" :key="index">{{ item.name }}
            </Option>
          </Select>
        </FormItem>
        <FormItem label="任务状态" prop="statusList">
          <Select v-model="params.statusList" multiple class="selectMultiple" placeholder="状态" style="width: 200px;">
            <Option v-for="(item,index) in baseData['专项任务状态']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="关键词" prop="keyword" show>
          <Input placeholder="项目名称、任务名称等" style="width: 120px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
    </ys-page-list>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok">确定</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import components from '@/mixins/components.js';

export default {
  name: '',
  mixins: [pageList, components],
  props: {
    IDs: {
      type: Array,
      default: () => {
        return []
      }
    },
    type: {
      type: Number,
      default: null
    },
    policyId: {
      type: Number,
      default: null
    },
    subType: {type: Number, default: null}
  },
  computed: {
    subTypeList() {
      const type = this.params.type || this.type
      let data = this.checkType.find(item => item.value === type)
      return data?.children || [];
    },
  },
  components: {},
  data() {
    return {
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {title: '保单编号', width: 180, key: 'number', align: 'center', renderConfig: {type: 'policyNoDesensitise'}},
        {width: 200, title: '任务名称', key: 'iname', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {minWidth: 250, title: '项目名称', key: 'projectName', align: 'left'},
        {width: 200, title: '投保机构', key: 'contractUnit', align: 'left'},
        {width: 120, title: '服务时间', key: 'serveTime', align: 'center', renderConfig: {type: 'formatDate'}},
        {width: 110, title: '状态', key: 'status', align: 'center', renderConfig: {type: 'baseData', parentName: '专项任务状态'}},
      ],
      params: {
        statusList: [],
        type: '',
        keyword: '',
        orgId: '',
        policyId: '',
      },
      modal: {}
    }
  },
  created() {},
  watch: {
    'params.type'() {
      this.$nextTick(() => {
        this.params.subType = ''
      })
    }
  },
  mounted() {
    this.params.orgId = this.userInfo.org_id;
    this.params.type = this.type;
    this.params.policyId = this.policyId;
    this.params.subType = this.subType
    this.getList();
  },
  methods: {}


}
</script>
