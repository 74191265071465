<template>
  <div class="home">
    <ys-page-list
        keyID="4E5C8F508B7F795BD1624D952D5CC9B6"
        ref="table"
        action="inspects/List"
        :params="params"
        :multiBtn="multiBtn"
        showContextMenu
        :multiColumn="true"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns"
    >
      <template slot="search">
        <FormItem label="验收状态" prop="status">
          <Select v-model="params.status" clearable placeholder="验收状态" style="width: 120px;">
            <Option v-for="(item,index) in (baseData['验收任务状态'] || []).filter(item=>item.value !== 0 && item.value !== 20)" :value="item.value" :key="index">{{ item.name }}
            </Option>
          </Select>
        </FormItem>
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="验收名称、项目名称、保单编号等" style="width: 180px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="detail">
        <Detail :mid="detailID"></Detail>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="600" title="验收">
      <DoInspects @on-ok="getList" :mid="detailID"></DoInspects>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import DoInspects from "@/views/inspects/doInspects.vue";
import Detail from "@/views/inspects/detail.vue";
import {acceptInspects} from "@/api/inspects";

export default {
  name: 'task',
  mixins: [pageList],
  components: {
    Detail,
    DoInspects,
  },
  data() {
    return {
      params: {
        status: '',
        keyword: ''
      },
      doFlag: false,
      tabNum: '',
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {title: '编号', width: 180, key: 'code', align: 'center'},
        {width: 250, title: '验收名称', key: 'iname', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {minWidth: 250, title: '项目名称', key: 'projectName', align: 'left'},
        {width: 200, title: '发布时间', key: 'publishedAt', align: 'left'},
        {width: 150, title: '发布人', key: 'publishedUser', align: 'left'},
        {width: 120, title: '创建时间', key: 'createdAt', align: 'center', renderConfig: {type: 'formatDate'}},
        {width: 120, title: '状态', key: 'status', align: 'center', renderConfig: {type: 'baseData', parentName: '验收任务状态'}},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              type: 'detail',
              title: '查看详情',
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        },
        {
          children: [
            {
              click: () => this.doIt(),
              title: '开始处理',
              icon: 'ios-clipboard',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status !==10
            }, {
              click: () => this.modShow = true,
              title: '验收',
              icon: 'ios-clipboard',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status !== 30
            },

          ]
        },
      ]
    }
  },

  mounted() {
    this.getList();
  },

  methods: {
    doIt(){
      this.$Modal.confirm({
        title: '提示',
        content: '确认要开始处理任务么?',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          acceptInspects({id: this.tableSelectItem[0].id}).then(res => {
            if (res.code === 200) {
              this.$Notice.success({
                title: '提示',
                desc: res.data.msg
              });
              this.getList();
            }
          })
        }
      })
    },
  }

}
</script>
