<template>
  <task :type="4" keyID="CFE99D4552E117C5B7A0B01E3B56064A"></task>
</template>

<script>
import Task from "@/views/task/index";
export default {
  name: "trainTask",
  components: {Task}
}
</script>

<style scoped>

</style>
