<template>
  <div>
    <ys-detail :icon="detailIcon" :loading="loading" :name="detail.iname">
      <Tag size="medium" color="blue" slot="status">{{ detail.status|getBaseDataName('验收任务状态') }}</Tag>
      <template slot="brief">
        <ys-cell title="完成期限" :label="detail.endAt|formatDate"/>
        <ys-cell title="项目名称" :label="detail.projectName"/>
        <ys-cell title="发布日期" :label="detail.publishedAt"/>
        <ys-cell title="备注" :label="detail.desc" style="word-break: break-all;"/>
      </template>
      <template slot="btn">
      </template>
      <template slot="main-left">
        <Tabs v-model="tab" type="card">
          <Tab-pane label="验收项目" icon="ios-create" name="1">
            <itemList :status="detail.status" :mid="detailID" v-if="tab==='1'"></itemList>
          </Tab-pane>
          <Tab-pane label="处理专家" icon="md-contact" name="2">
            <experts :midData="[detail]" v-if="tab==='2'"></experts>
          </Tab-pane>
        </Tabs>
      </template>
      <template slot="main-right">
        <h1 class="h1Title">其他信息</h1>
      </template>
    </ys-detail>
  </div>
</template>

<script>
import detail from '@/mixins/detail.js'
import {inspectsInfo} from "@/api/inspects";

export default {
  name: '',
  mixins: [detail],
  props: {},
  components: {
    'itemList': () => import('@/views/inspects/detail/itemList.vue'),
    'experts': () => import('@/views/inspects/detail/experts.vue'),
  },
  data() {
    return {
      dictList: [],
      modShow: false,
      doFlag: false
    }
  },
  computed: {},
  created() {
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.loading = true;
      inspectsInfo({id: this.detailID}).then((res) => {
        this.loading = false
        this.detail = res.data;
        this.setTagName(this.detail.iname);
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped lang="less">

</style>