<template>
  <Card class="update" title="系统通知" :dis-hover="true" :bordered="false" :style="{'margin-top': '10px'}">
    <!-- <ul class="notice" :style="{'max-height': this.height - 150 + 'px', overflowY: 'auto'}"> -->
    <ul class="notice">
      <li v-for="(item,index) in noticeList" :key="index">
        <Avatar :icon="resourceType[item.resourceType].icon" :style="{backgroundColor: resourceType[item.resourceType].backgroundColor}" class="avatar" :size="40"/>
        <div class="content">
          <h4>{{ item.title }}
            <Time :time="formatDate(item.createdAt,'yyyy/MM/dd hh:mm:ss')" class="time"/>
          </h4>
          <p class="info">{{ item.message }}</p>
        </div>
      </li>
    </ul>
  </Card>
</template>

<script>
import {noticeList} from "@/api/common";
import {mapGetters} from 'vuex'
import {formatDate} from 'ys-admin'

export default {
  name: "notice",
  data() {
    return {
      noticeList: [],
      resourceType: {
        1: {backgroundColor: '#e66', icon: 'md-locate'},//整改单
        2: {backgroundColor: '#fac858', icon: 'logo-buffer'},//检查单
        3: {backgroundColor: '#3ba272', icon: 'md-contact'},//专家
        4: {backgroundColor: '#ff6600', icon: 'ios-information-circle'},//风险
        5: {backgroundColor: '#91cc75', icon: 'ios-list-box'},//风险回复
        6: {backgroundColor: '#5470c6', icon: 'md-copy'},//报告
        7: {backgroundColor: '#73c0de', icon: 'ios-book'},//检查单组
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'height']),
    formatDate(){
      return formatDate
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      noticeList({orgId: this.userInfo.org_id, limit: 6}).then((res) => {
        this.noticeList = res.data.list
      })
    }
  }

}
</script>
<style lang="less" scoped>
.notice {
  li {
    display: flex;
    list-style: none;
    border-bottom: 1px solid #ececec;
    padding: 10px;
    cursor: pointer;

    &:hover {
      background-color: #fafafa;
    }

    .time {
      font-weight: normal;
    }

    .content {
      padding-left: 15px;
      flex: 1;

      .info {
        padding-top: 3px;
        font-size: 14px;
        color: #999999;
      }
    }


  }
}
</style>