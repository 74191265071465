<template>
  <div class="myMessageSet">
    <h1 class="h1Title">我的信息设置</h1>
    <Row>
      <Col span="4">
        <ul class="tabList">
          <li v-for="(item,index) in tabList" :class="{active:tab===index}" @click="changeTab(index)" :key="index">{{ item.name }}</li>
        </ul>
      </Col>
      <Col span="20">
        <div class="content">
          <template v-for="(item,index) in tabList">
            <component :is="item.components" v-if="tab===index" :key="index"></component>
          </template>
        </div>
      </Col>
    </Row>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'

export default {
  name: '',
  mixins: [pageList],

  data() {
    return {
      tabList: [
        {name: '我的信息', desc: '', components: () => import('@/views/myMessageSet/myMessage.vue')},
        {name: '修改密码', desc: '', components: () => import('@/views/myMessageSet/password.vue')},
      ],
      tab: 0,
    }
  },
  mounted() {
  },
  computed: {},
  methods: {
    changeTab(index) {
      this.tab = index;
    }
  }
}
</script>

<style lang="less" scoped>
.myMessageSet {
  padding: 10px;
  overflow-y: auto;

  .tabList {
    height: calc(100vh - 170px);
    padding-right: 10px;

    li {
      list-style: none;
      height: 35px;
      line-height: 35px;
      padding: 0 20px;
      cursor: pointer;
    }
  }

  .content {
    padding: 0 20px;
  }
}
</style>