<template>
  <div>
    <Form ref="form" class="editForm " :model="form" :rules="ruleValidate" :label-width="80" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <FormItem label="标题" prop="title">
        <Input v-model="form.title" placeholder="标题"/>
      </FormItem>
      <FormItem label="描述" prop="content" style="width: 100%">
        <Input v-model="form.content" type="textarea" maxlength="128" show-word-limit :autosize="{minRows: 5,maxRows: 10}" placeholder="描述"></Input>
      </FormItem>
      <FormItem label="匿名" prop="isAnonymous">
        <Tooltip content="匿名后将不会上传您的个人信息！" placement="top">
          <i-switch v-model="form.isAnonymous" :true-value="1" :false-value="0">
            <template #open>
              <span>是</span>
            </template>
            <template #close>
              <span>否</span>
            </template>
          </i-switch>
        </Tooltip>
      </FormItem>
      <FormItem label="附件" prop="fileList">
        <ysUpload @on-success="getFile" :format="['jpg','jpeg','png','gif']" :headers="{token:token}" action="/web/resource/UploadFile"></ysUpload>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="save">保存并发送</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit'

export default {
  name: 'complaintEdit',
  mixins: [edit],
  data() {
    return {
      ruleValidate: {
        title: [
          {required: true, message: '请填写标题', trigger: 'blur'}
        ],
        content: [
          {required: true, message: '请填写描述', trigger: 'blur'}
        ],
      },
      form: {
        id: '',
        isAnonymous: 0,
        title: '',
        content: '',
        fileList: '',
      },
    }
  },

  mounted() {

  },
  methods: {
    save() {
      this.$Modal.confirm({
        title: '提示',
        content: '确认要提交该投诉建议么？提交后将直接发送!',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          this.ok('/complaint/Create');
        }
      })
    },
    getFile(data) {
      this.form.fileList = data.map(item => item.id);
    },
  }
}
</script>

<style lang="less" scoped>
</style>
