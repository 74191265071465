<template>
<div class="baseTotal">
  <div class="myMessage">
    <div style="height: 50%;display: flex;justify-content: center;align-items: flex-end;">
      <Avatar :src="`${config.baseURL}/resource/GetFile/${userInfo.expert.pictureId}?size=100`" size="70" v-if="userInfo.expert.pictureId"/>
      <Avatar icon="ios-person" size="70" v-else/>
    </div>
    <p style="line-height: 35px;font-weight: bold;">HI，{{ userInfo.name }}</p>
    <p style="line-height: 25px;" v-if="userInfo.phone">
      <Icon type="ios-call" />
      <span style="color: #A8A8A8;font-size: 10px;">{{ userInfo.phone|phoneDesensitise }}</span>
    </p>
    <p style="line-height: 25px;" v-if="userInfo.mail">
      <Icon type="ios-mail" />
      <span style="color: #A8A8A8;font-size: 10px;">{{ userInfo.mail }}</span>
    </p>
  </div>
  <div class="toatal">
    <Card title="实时数据统计" :dis-hover="true" :bordered="false">
      <template #extra><span>更新时间： {{ new Date()|formatDate('yyyy-MM-dd hh:mm:ss')}}</span></template>
      <ul class="total">
        <router-link custom v-slot="{ navigate }" :to="item.path" v-for="item in totalList" :key="item.title">
          <li @click="navigate" @keypress.enter="navigate" role="link">
            <p class="number">{{ item.num }}</p>
            <p class="title">{{ item.title }}</p>
          </li> 
        </router-link>
      </ul>
      <Alert type="warning" style="margin-top: 20px;">
        <template #icon>
          <Icon type="ios-alert-outline"/>
        </template>
        {{ notice }}
      </Alert>
      <!-- <Alert type="warning" style="margin-top: 20px;">
        <Icon type="ios-alert-outline" v-solt:icon/>
        暂无通知
      </Alert> -->
    </Card>
  </div>
</div>
</template>

<script>
import {mapGetters} from 'vuex'
import {checkCount} from "@/api/common";

export default {
  name: 'BaseTotal',
  data() {
    return {
      loading: true,
      notice: '以上统计数据包含专项任务和安责险任务，点击后跳转的页面仅显示安责险任务，请知晓！',
      totalList: [
        {
          title: '当前任务总数',
          num: 0,
          icon: 'ios-barcode-outline',
          color: '#0075ff',
          backgroundColor: '#f7f7fd',
          path: '/main/task'
        },
        {
          title: '进行中任务数',
          num: 0,
          icon: 'ios-create-outline',
          color: '#ff6600',
          backgroundColor: '#fffbfa',
          path: '/main/task?statusList=[2,3,5,6]'
        },
        {
          title: '已结束任务数',
          num: 0,
          icon: 'ios-checkmark-circle-outline',
          backgroundColor: '#f7fff7',
          color: '#00e30b',
          path: '/main/task?statusList=[99]'
        },
        {
          title: '已逾期任务总数',
          num: 0,
          color: '#ff003b',
          backgroundColor: '#fff9fa',
          icon: 'ios-clock-outline',
          path: '/main/task?overDue=1'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['config', 'userInfo']),
  },
  mounted() {
    this.$all([
      checkCount({orgId: this.userInfo.org_id}),
      checkCount({orgId: this.userInfo.org_id, statusList: [1, 2, 3, 5, 6]}),
      checkCount({orgId: this.userInfo.org_id, statusList: [99]}),
      checkCount({orgId: this.userInfo.org_id, overdue: 1}),
    ]).then(res => {
      this.totalList.forEach((item, index) => {
        item.num = res[index].data;
      })
    })
  }
}
</script>

<style lang="less" scoped>
.baseTotal {
  display: flex;
  .myMessage {
    width: 20%;
    text-align: center;
  }
  .toatal {
    flex: 1;
    .total {
      display: flex;
      li {
        list-style: none;
        flex: 1;
        padding: 5px 0;
        width: 130px;
        margin: 0 10px;
        text-align: center;
        border: 1px solid rgba(0, 0, 0, 0);

        p.number {
          font-size: 30px;
          padding: 5px 0;
        }

        // &:hover {
        //   cursor: pointer;
        //   border: 1px solid rgba(0, 0, 0, 0.05);
        // }
      }
    }
  }
}
</style>
