<template>
  <div :style="{maxHeight:baseInfoHeight+'px'}" style="overflow-y: auto">
    <ys-cell-group title="" :gutter="2">
      <ys-cell title="任务名称" :label="detail.iname" block/>
      <ys-cell title="任务编号" :label="detail.number" block/>
      <ys-cell title="任务状态" :label="detail.status|getBaseDataName( '抽查任务状态')"/>
      <ys-cell title="备注" :label="detail.remarks" block/>
      <ys-cell title="创建人" :label="detail.createdUser"/>
      <ys-cell title="创建时间" :label="detail.createdAt|formatDate"/>
      <ys-cell title="发布日期" :label="detail.createdAt|formatDate"/>
      <ys-cell title="计划服务时间" :label="detail.serveTime|formatDate"/>
      <ys-cell title="截止完成日" :label="detail.finishAt|formatDate"/>
      <ys-cell title="完成时间" :label="detail.finishedAt|formatDate('yyyy-MM-dd hh:mm:ss')"/>
      <ys-cell title="完成任务操作人" :label="detail.finishedUser"/>
      <ys-cell title="抽查结果" :label="detail.result|getBaseDataName('抽查任务结果')"/>
      <ys-cell title="抽查结论" :label="detail.conclusion" block/>
    </ys-cell-group>
    <ys-cell-group title="附件">
      <div v-viewer="{url: 'data-src'}">
        <ys-image
          :width="115"
          :height="115"
          :src="config.baseURL + '/resource/GetFile/'+fileItem.id+'?size=100'"
          :data-src="config.baseURL + '/resource/GetFile/'+fileItem.id"
          v-for="fileItem in detail.fileList"
          :key="fileItem.id"
        ></ys-image>
      </div>
    </ys-cell-group>
  </div>
</template>

<script>
import baseInfo from "@/mixins/baseInfo";

export default {
  name: "baseInfo",
  mixins: [baseInfo],
  props: {azxID: [String, Number]},
  data() {
    return {
      detail: {},
    }
  },
  watch: {},
  mounted() {
    this.detail = this.midData[0]
  },
  methods: {}
}
</script>

<style scoped lang="less">
.notFound {
  text-align: center;
  padding: 30px;
  line-height: 40px;
  font-size: 16px;
}
</style>