<template>
  <Card title="我的待办日历" class="tasks" :dis-hover="true" :bordered="false">
    <template #extra>
      <span>仅显示前后各1个月的待办数据</span>
    </template>
    <div class="calendar">
      <div class="left"  @click="switchWeek('perv')">
        <Icon type="ios-arrow-back" />
      </div>
      <div class="content">
        <Badge :count="countNum(item)" class="item" :class="{'item-click': currDay === item.y_m_d}" v-for="(item, index) in currMonWeekList[currMonWeekList.findIndex(item => item.some(ite => ite.y_m_d === currDay))]" :key="index">
          <div @click="itemClick(item)">
            <p>{{ item.week }}</p>
            <p>{{ item.day }}</p>
            <p>{{ item.y_m }}</p>
          </div>
        </Badge>
      </div>
      <div class="right"  @click="switchWeek('next')">
        <Icon type="ios-arrow-forward" />
      </div>
    </div>
    <Tabs :animated="false" v-model="tab" type="card" :key="new Date(currDay).getTime()">
      <TabPane label="全部" name="1">
        <!-- <allList v-if="tab === '1'" :aMonthAgoDay="aMonthAgoDay" :aMonthLaterDay="aMonthLaterDay" @getTableData="getTableData"></allList> -->
      </TabPane>
      <TabPane label="检查任务" name="2">
        <!-- <do-special :tableHeight="'auto'" :ServeTimeStartAt="aMonthAgoDay" :ServeTimeEndAt="aMonthLaterDay" :type="[1, 2]" :statusList="[2,3,5,6,0]" v-if="tab==='2'" @getTableData="getTableData"></do-special> -->
      </TabPane>
      <TabPane label="风险整改" name="3">
        <!-- 显示整改单状态是待审核的 -->
        <!-- <problems-list :tableHeight="'auto'" :UpdatedStartAt="aMonthAgoDay" :UpdatedEndAt="aMonthLaterDay" :amendStatus="3" v-if="tab==='3'" @getTableData="getTableData"></problems-list> -->
      </TabPane>
      <TabPane label="服务计划" name="4">
        <!-- 查询截止时间 -->
        <!-- <PlanList :tableHeight="'auto'" :isHome="false" :FinishStartAt="aMonthAgoDay" :FinishEndAt="aMonthLaterDay" :noCheck="1" v-if="tab==='4'" @getTableData="getTableData"></PlanList> -->
      </TabPane>
      <TabPane label="报告" name="5">
        <!-- 查询截止日期，未完成且需审核数据(只显示任务开启了审核的数据) -->
        <!-- <report :checkStatusList="[20, 40]" :isCheckReport="1" :status="0" :UpdatedStartAt="aMonthAgoDay" :UpdatedEndAt="aMonthLaterDay" v-if="tab === '5'"></report> -->
      </TabPane>
      <TabPane label="整改单" name="6">
        <!-- 查询截止日期，未完成且需审核数据(只显示任务开启了审核的数据) -->
        <!-- <rectifications :checkStatusList="[10, 20]" :isCheckReport="1" :status="1" :UpdatedStartAt="aMonthAgoDay" :UpdatedEndAt="aMonthLaterDay" v-if="tab === '6'"></rectifications> -->
      </TabPane>
    </Tabs>
  </Card>
</template>

<script>
// import allList from "@/views/home/tasks/all/index.vue";
// import DoSpecial from "@/views/home/tasks/doSpecial/index.vue";
// import ProblemsList from "@/views/home/tasks/problems/index.vue";
// import PlanList from "@/views/home/tasks/plan/index.vue";
// import report from "@/views/home/tasks/report/index.vue";
// import rectifications from "@/views/home/tasks/rectifications/index.vue";

export default {
  name: 'task',
  data() {
    return {
      tab: '1',
      currDay: '',
      aMonthAgoDay: '',
      aMonthLaterDay: '',
      currMonWeekList: [],
      iosOrmacOS: '',
      count: 0,
      tableData: []
    }
  },
  components: {
    // allList,
    // DoSpecial,
    // ProblemsList,
    // PlanList,
    // report,
    // rectifications,
  },
  computed: {
    countNum() {
      return (item) => {
        // 计算各自日期该显示数据,显示每个日期前后各一个月的数据
        // switch(this.tab) {
        //   case '1':
        //     break;
        //   case '2':
        //     break;
        //   case '3':
        //     break;
        //   case '4':
        //     break;
        //   case '5':
        //     break;
        // }
        // console.log('tableData', this.tableData);
        // 现只返回当前时间下的当前tab下的数据
        return item.y_m_d === this.currDay ? this.tableData.length : 0
      }
    }
  },
  mounted() {
    this.getOS()
    const date = new Date()
    this.currDay = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`.replace(/-/g, this.iosOrmacOS ? '/' : '-')

    this.getMonthWeek()
  },
  methods: {
    getOS() {
      var u = navigator.userAgent;
      if (!!u.match(/Macintosh/i) || u.match(/MacIntel/i)) {
        // return 'macOS';
        this.iosOrmacOS = true
      } else if (!!u.match(/iphone/i) || u.match(/Ipad/i)) {
        // return 'ios';
        this.iosOrmacOS = true
      } else {
        // return 'other';
        this.iosOrmacOS = false
      }
    },
    getMonthWeek(y = '', m = '') {
      const year = y ? y : new Date().getFullYear()
      const month = m ? m : new Date().getMonth() + 1
      // const day = new Date().getDate()
      // 当前月份的天数
      const currMonNum = new Date(year, month, 0).getDate()
      // 当前月份按周分割数组
      const currMonWeekList = []
      // 一个月是5个周,要么是第一周要么是最后一周要么是两周都是有空缺的
      for(let i = 1; i <= currMonNum;i ++) {
        let date = `${year}-${month}-${i}`.replace(/-/g, this.iosOrmacOS ? '/' : '-')
        let formatDate = this.formatDate(new Date(date))
        if(!Array.isArray(currMonWeekList[formatDate.monthWeekIndex - 1])) currMonWeekList[formatDate.monthWeekIndex - 1] = []
        // 将周日放在每一周的结尾
        if(formatDate.week === '日') {
          currMonWeekList[formatDate.monthWeekIndex === 1 ? 0 : formatDate.monthWeekIndex - 2].push(formatDate)
        } else {
          currMonWeekList[formatDate.monthWeekIndex - 1].push(formatDate)
        }
      }
      // 不足，向上一个月借
      if(currMonWeekList[0].length < 7) {
        let prevList = []
        let prevYear = year
        let prevMonth = month - 1
        // 往上一周是否为上一年
        if(month === 1) {
          prevYear -= 1
          prevMonth = 12
        }
        const prevMonNum = new Date(prevYear, prevMonth, 0).getDate()
        for(let i = prevMonNum;i > prevMonNum - (7 - currMonWeekList[0].length);i --) {
          let date = `${prevYear}-${prevMonth}-${i}`.replace(/-/g, this.iosOrmacOS ? '/' : '-')
          prevList.push(this.formatDate(new Date(date)))
        }
        currMonWeekList[0].unshift(...prevList.reverse())
      }
      // 不足，向下一月借
      if(currMonWeekList[currMonWeekList.length - 1].length < 7) {
        let nextList = []
        let nextYear = year
        let nextMonth = month + 1
        // 往下一周是否为下一年
        if (month === 12) {
          nextYear += 1
          nextMonth = 1
        }
        for(let i = 1;i <= 7 - currMonWeekList[currMonWeekList.length - 1].length;i ++) {
          let date = `${nextYear}-${nextMonth}-${i}`.replace(/-/g, this.iosOrmacOS ? '/' : '-')
          nextList.push(this.formatDate(new Date(date)))
        }
        currMonWeekList[currMonWeekList.length - 1].push(...nextList)
      }
      this.currMonWeekList = currMonWeekList
    },
    formatDate(date) {
      var year = date.getFullYear()
      var month = (date.getMonth()+1)
      var day = date.getDate()
      var week = ['日', '一', '二', '三', '四', '五', '六'][date.getDay()];
      return {
        year,
        month,
        day,
        week,
        monthWeekIndex: Math.ceil((day + (6 - date.getDay())) / 7),
        y_m: `${year}-${month}`.replace(/-/g, this.iosOrmacOS ? '/' : '-'),
        y_m_d: `${year}-${month}-${day}`.replace(/-/g, this.iosOrmacOS ? '/' : '-'),
      }
    },
    // 上下切换周,直接改变当前日期为上下周的第一天即可
    switchWeek(mode) {
      let index = this.currMonWeekList.findIndex(item => item.some(ite => ite.y_m_d === this.currDay))
      switch(mode) {
        case 'perv':
          if(index <= 0) return
          this.currDay =  this.currMonWeekList[index - 1][0].y_m_d
          break;
        case 'next':
          if(index >= this.currMonWeekList.length - 1) return
          this.currDay =  this.currMonWeekList[index + 1][0].y_m_d
          break;
      }
    },
    itemClick(item) {
      this.currDay = item.y_m_d

    },
    getTableData(data) {
      this.tableData = data
    }
  },
  watch: {
    currDay() {
      const dateAgo = new Date(new Date(this.currDay).setMonth(new Date(this.currDay).getMonth() - 1))
      this.aMonthAgoDay = `${dateAgo.getFullYear()}-${dateAgo.getMonth() + 1}-${dateAgo.getDate()}`.replace(/-/g, this.iosOrmacOS ? '/' : '-')

      const dateLater = new Date(new Date(this.currDay).setMonth(new Date(this.currDay).getMonth() + 1))
      this.aMonthLaterDay = `${dateLater.getFullYear()}-${dateLater.getMonth() + 1}-${dateLater.getDate()}`.replace(/-/g, this.iosOrmacOS ? '/' : '-')
    }
  }
}
</script>
<style lang="less" scoped>
.task {
  background-color: #fff;
  margin-bottom: 10px;

  .title {
    border-bottom: 1px solid #ececec;
    padding: 5px 7px;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}
.calendar {
  height: 100px;
  display: flex;
  justify-content: space-between;
  .content {
    width: 90%;
    display: flex;
    justify-content: space-between;
    .item {
      width: ceil(100% / 10);
      height: 70%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      p {
        line-height: 20px;
        &:nth-child(1) {
          font-size: 12px;
          color: #959595;
        }
        &:nth-child(2) {
          font-size: 16px;
          font-weight: 400;
        }
        &:nth-child(3) {
          font-size: 12px;
          color: #C8C8C8;
        }
      }
      &-click {
        background: #5E81FF !important;
        color: #ffffff;
        p {
          &:nth-child(1) {
            color: #C6D3FF;
          }
          &:nth-child(3) {
            font-size: 12px;
            color: #C6D3FF;
          }
        }
      }
    }
  }
  .left, .right {
    width: 5%;
    height: 70%;
    font-size: 30px;
    display: flex;
    align-items: center;
  }
  .left {
    justify-content: flex-start;
  }
  .right {
    justify-content: flex-end;
  }
}
</style>
