<template>
  <div>
    <Form ref="form" slot="main" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" inline :label-width="120" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <FormItem label="项目名称" prop="iname">
        <Input v-model="form.iname" placeholder="项目名称"></Input>
      </FormItem>
      <FormItem label="施工许可证号" prop="licenseNum">
        <Input v-model="form.licenseNum" placeholder="施工许可证号" search enter-button="生成" @on-search="autoGenerate"></Input>
      </FormItem>
      <FormItem label="安监号" prop="code">
        <Input v-model="form.code" placeholder="安监号"></Input>
      </FormItem>
      <FormItem label="工程类型" prop="category">
        <Select v-model="form.category" placeholder="工程类型">
          <Option v-for="(item,index) in baseData['项目类型']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <!-- <FormItem label="计划开工日期" prop="startReal">
        <ys-date-picker v-model="form.startPlan" style="width: 100%;" placeholder="开工日期"></ys-date-picker>
      </FormItem>
      <FormItem label="计划完工日期" prop="endReal">
        <ys-date-picker v-model="form.endPlan" style="width: 100%;" placeholder="完工日期"></ys-date-picker>
      </FormItem> -->
      <FormItem label="实际开工日期" prop="startReal">
        <ys-date-picker v-model="form.startReal" style="width: 100%;" placeholder="实际开工日期"></ys-date-picker>
      </FormItem>
      <FormItem label="实际完工日期" prop="endReal">
        <ys-date-picker v-model="form.endReal" style="width: 100%;" placeholder="实际完工日期"></ys-date-picker>
      </FormItem>
      <FormItem label="项目负责人" prop="principalName">
        <Input v-model="form.principalName" placeholder="项目负责人"></Input>
      </FormItem>
      <FormItem label="负责人电话" prop="principalPhone">
        <Input v-model="form.principalPhone" placeholder="负责人电话"></Input>
      </FormItem>
      <FormItem label="安责险联络员姓名" prop="safetyOfficerName">
        <Input v-model="form.safetyOfficerName" placeholder="安责险联络员姓名"></Input>
      </FormItem>
      <FormItem label="安责险联络员电话" prop="safetyOfficerPhone">
        <Input v-model="form.safetyOfficerPhone" placeholder="安责险联络员电话"></Input>
      </FormItem>
      <FormItem label="合同造价" prop="contractPrice">
        <Input type="number" v-model="form.contractPrice" placeholder="合同造价"><span slot="append">元</span></Input>
      </FormItem>
      <FormItem label="工程造价" prop="amount">
        <Input type="number" v-model="form.amount" placeholder="工程造价"><span slot="append">元</span></Input>
      </FormItem>
      <FormItem label="总面积" prop="buildArea">
        <Input type="number" v-model="form.buildArea" placeholder="总面积"><span slot="append">㎡</span></Input>
      </FormItem>
      <FormItem label="总长度" prop="buildLength">
        <Input type="number" v-model="form.buildLength" placeholder="总长度"><span slot="append">米</span></Input>
      </FormItem>
      <FormItem label="所在地区" prop="area">
        <ys-region changeOnSelect v-model="form.area" @on-change="getArea" placeholder="所在地区"/>
      </FormItem>
      <FormItem label="项目阶段" prop="status">
        <Select v-model="form.status" transfer clearable placeholder="项目阶段">
          <Option v-for="(item,index) in baseData['项目进度']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="形象进度" prop="progress">
        <Select v-model="form.progress" transfer clearable placeholder="形象进度">
          <Option v-for="(item,index) in baseData['施工阶段']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="高度" prop="buildHeight">
        <Input type="number" v-model="form.buildHeight" placeholder="高度"><span slot="append">米</span></Input>
      </FormItem>
      <FormItem label="最高楼层" prop="floorHeight">
        <Input type="number" v-model="form.floorHeight" placeholder="最高楼层"><span slot="append">层</span></Input>
      </FormItem>
      <FormItem label="经纬度" prop="lngLat" style="width: 80%">
        <Input v-model="form.lngLat" @on-change="lngLatChange" placeholder="经纬度"></Input>
      </FormItem>
      <FormItem :labelWidth="0" style="width: 20%">
        <Button type="primary" long @click="lnglatFlag=true">手动选择经纬度</Button>
      </FormItem>
      <FormItem label="项目地址" prop="address" style="width: 100%">
        <Input v-model="form.address" placeholder="项目地址"></Input>
      </FormItem>
      <FormItem label="施工许可证照片" prop="licenseFileList" style="width: 100%;text-align: center">
        <ysUpload
            @on-success="bLFile(arguments,'licenseFileList')"
            :headers="{token:token}"
            type="halfList"
            :format="['jpg','jpeg','png','gif']"
            :defaultList.sync="licenseFileList"
            action="/web/resource/UploadFile"
        ></ysUpload>
      </FormItem>
      <FormItem label="平面图" prop="floorViewFileList" style="width: 100%;text-align: center">
        <ysUpload
            @on-success="bLFile(arguments,'floorViewFileList')"
            :headers="{token:token}"
            type="halfList"
            :format="['jpg','jpeg','png','gif']"
            :defaultList.sync="floorViewFileList"
            action="/web/resource/UploadFile"
        ></ysUpload>
      </FormItem>
      <FormItem label="鸟瞰图" prop="aerialViewFileList" style="width: 100%;text-align: center">
        <ysUpload
            @on-success="bLFile(arguments,'aerialViewFileList')"
            :headers="{token:token}"
            type="halfList"
            :format="['jpg','jpeg','png','gif']"
            :defaultList.sync="aerialViewFileList"
            action="/web/resource/UploadFile"
        ></ysUpload>
      </FormItem>
      <FormItem label="项目简介" prop="description" style="width: 100%">
        <Input v-model="form.description" type="textarea" maxlength="2000" show-word-limit style="width: 100%" :autosize="{minRows: 8, maxRows: 10}" placeholder="项目简介"></Input>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea" slot="bottomBtn" ref="bottomBtn">
      <Button type="primary" @click="save('onlySave')">仅保存</Button>
      <Button type="primary" @click="save('saveAndNew')">保存并新增参建单位</Button>
      <Button @click="close">取消</Button>
    </div>
    <ys-modal v-model="lnglatFlag" :width="1220" title="手动选择经纬度">
      <iframes></iframes>
    </ys-modal>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {projectInfo} from "@/api/projects";
import iframes from "@/views/projects/iframes.vue";

export default {
  name: 'projectEdit',
  mixins: [edit],
  components: {
    iframes
  },
  data() {
    return {
      ruleValidate: {
        code: [
          {required: true, message: '请填安监号', trigger: 'blur'}
        ],
        iname: [
          {required: true, message: '请填项目名称', trigger: 'blur'}
        ],
        licenseNum: [
          {required: true, message: '请填写施工许可证号！', trigger: 'blur'}
        ],
        area: [
          {required: true, message: '请选择所在区域', trigger: 'change'}
        ],
      },
      lnglatFlag: false,
      licenseFileList: [],
      floorViewFileList: [],
      aerialViewFileList: [],
      form: {
        iname: '',
        orgList: [],
        licenseNum: '',
        industryType: '1',
        tag: 1,
        principalName: '',
        principalPhone: '',
        safetyOfficerName: '',
        safetyOfficerPhone: '',
        category: '99',
        startPlan: '',
        code: '',
        startReal: '',
        endPlan: '',
        endReal: '',
        id: '',
        lng: '',
        lat: '',
        provinceCode: '',
        cityCode: '',
        countyCode: '',
        address: '',
        contractPrice: '',
        amount: '',
        buildLength: '',
        buildArea: '',
        buildHeight: '',
        floorHeight: '',
        lngLat: '',
        area: '',
        status: '',
        progress: '',
        description: '',
        licenseFileList: [],
        floorViewFileList: [],
        aerialViewFileList: []
      },
    }
  },
  computed: {},
  mounted() {
    if (this.mid) {
      projectInfo({id: this.mid}).then((res) => {
        Object.keys(this.form).forEach((item) => {
          this.form[item] = res.data[item] || null;
        })
        this.licenseFileList = res.data.licenseFileList||[];
        this.floorViewFileList = res.data.floorViewFileList||[];
        this.aerialViewFileList = res.data.aerialViewFileList||[];
        this.form.licenseFileList = this.licenseFileList.map(item => item.id);
        this.form.floorViewFileList = this.floorViewFileList.map(item => item.id);
        this.form.aerialViewFileList = this.aerialViewFileList.map(item => item.id);
        this.form.area = res.data.countyCode || res.data.cityCode || res.data.provinceCode;
        this.form.lngLat = this.form.lat && this.form.lng ? (this.form.lat + ',' + this.form.lng) : '';
      })
    }
  },
  methods: {
    lngLatChange() {
      this.form.lng = this.form.lngLat.split(',')[1] || ''
      this.form.lat = this.form.lngLat.split(',')[0] || ''
    },
    getArea(data) {
      this.form.provinceCode = data[0] ? data[0].code : '';
      this.form.cityCode = data[1] ? data[1].code : '';
      this.form.countyCode = data[2] ? data[2].code : '';
    },
    bLFile(data, key) {
      this.form[key] = data[0].map(item => item.id);
    },
    autoGenerate() {
      this.form.licenseNum = `XKZ${new Date().getTime()}`
    },
    save(mode) {
      if (this.form.endReal && this.form.startReal && new Date(this.form.endReal).getTime() < new Date(this.form.startReal).getTime()) {
        this.$Message.error('完工日期不得小于开工日期！');
        return
      }
      switch(mode) {
        case 'onlySave':
          this.ok(this.form.id?'/projects/pcEdit':'/projects/Add')
          break;
        case 'saveAndNew':
          this.ok(this.form.id?'/projects/pcEdit':'/projects/Add','detail')
          break;
      }
    },
  }
}
</script>

<style lang="less" scoped>
</style>
