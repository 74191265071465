import {get} from 'ys-admin'

/**
 * 获取菜单
 * @param {Object} params
 * @returns {Object}
 */
 export const getRoleMenuList = function (params) {
  return get('/menu/RoleMenuList', params)
};

/**
 * 获取角色详情
 * @param {Object} params
 * @returns {Object}
 */
 export const getRoleInfo = function (params) {
  return get('/permission/role/GetInfo', params)
};
