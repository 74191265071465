<template>
  <div>
    <Split v-model="split" :style="{height:'calc(100vh - 200px)'}" style="overflow-y: auto;;">
      <div slot="left" class="splitPaneLeft">
        <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="80" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
          <FormItem label="角色名称" prop="iname">
            <Input v-model="form.iname" placeholder="角色名称"/>
          </FormItem>
        </Form>
      </div>
      <div slot="right" style="padding-left: 20px;">
        <h1 class="h1Title">角色权限</h1>
        <div class="splitPaneRight">
          <div class="operationBtn">
            <ButtonGroup>
              <Button @click="check('all')">全选</Button>
              <Button @click="check('reverse')">反选</Button>
              <Button @click="check('cancel')">取消全选</Button>
            </ButtonGroup>
          </div>
          <Tree ref="menuTree" :data="MenuList" show-checkbox multiple check-directly check-strictly></Tree>
        </div>
      </div>
    </Split>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="save">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {getRoleMenuList, getRoleInfo} from "@/api/role";

export default {
  name: 'projectEdit',
  components: {},
  mixins: [edit],
  props: {oType: {type: Number, default: 1}},
  data() {
    return {
      ruleValidate: {
        iname: [
          {required: true, message: '请填写角色名称', trigger: 'blur'}
        ],
        menuIds: [
          {required: true, message: '请选择角色权限', trigger: 'chang', type: 'array'}
        ],
      },
      split: 0.5, // 分割比例
      menuAllList: [], // 所有菜单数据
      MenuList: [], // 将hideInMenu为true即不显示出来的菜单过滤掉后的数据
      orgFlag: false,
      form: {
        id: '',
        orgId: '',
        iname: '',
        category: 5, // 平台类型，必传，专家端：5
        menuIds: [],
      },
    }
  },
  created() {
  },
  mounted() {
    if (this.mid) {
      getRoleInfo({id: this.mid}).then(res => {
        if (res.code === 200) {
          Object.keys(this.form).forEach((item) => {
            this.form[item] = res.data[item]
          })
        }
      })
    } else {
      this.form.orgId = this.userInfo.org_id;
    }
    getRoleMenuList({oType: 5, roleId: this.mid}).then(res => {
      if (res.code === 200) {
        this.menuAllList = res.data || [];
        // 转树形结构,并将hideInMenu为false需要显示在菜单上的数据过滤出来
        this.MenuList = this.$listToTree(this.menuAllList.filter(item => !item.hideInMenu), {parentKey: 'parentId', sonKey: 'id', opLevelVal: 0});
      }
    })
  },
  methods: {
    deepDoTree(ary, type) {
      return ary.map(({...item}) => {
        switch (type) {
          case 'all':
            item.checked = true;
            break;
          case 'reverse':
            item.checked = !item.checked;
            break;
          case 'cancel':
            item.checked = false;
            break;
        }
        if (Array.isArray(item.children) && item.children.length > 0) {
          item.children = this.deepDoTree(item.children, type);
        }
        return item;
      })
    },
    check(type) {
      this.MenuList = this.deepDoTree((this.MenuList || []), type);
    },
    save() {
      const list = this.$refs.menuTree.getCheckedNodes().map(listItem => listItem.id)
      this.form.menuIds = [];
      list.forEach(treeItem => {
        this.form.menuIds.push.apply(this.form.menuIds, this.menuAllList.filter(item => item.relationId === treeItem).map(listItem => listItem.id))
      })
      this.form.menuIds.push.apply(this.form.menuIds, list)
      this.ok('/permission/role/AddOrEdit')
    },
  }
}
</script>

<style lang="less" scoped>

</style>


