<template>
  <div class="home">
    <ys-page-list
        ref="table"
        keyID="B5E5FAF629EC7E3BB28129B73D00DB36"
        action="/servePlan/subPlan/List"
        :multiBtn="multiBtn"
        :tableHeight="tableHeight"
        :params="params"
        showContextMenu
        :multiColumn="true"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns"
    >
      <template slot="search">
        <FormItem label="关联状态" prop="haveCheck">
          <Select v-model="params.haveCheck" transfer placeholder="关联状态" clearable style="width: 120px;">
            <Option v-for="(item,index) in baseData['子计划关联状态']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <!-- <FormItem label="总计划审核状态" prop="statusList">
          <Select v-model="params.statusList" :max-tag-count="2" style="width: 120px" transfer multiple class="selectMultiple" clearable placeholder="状态">
            <Option v-for="(item,index) in baseData['保单总计划状态']" :value="item.value" :key="index">{{ item.name }}
            </Option>
          </Select>
        </FormItem> -->
        <FormItem label="类型" prop="type">
          <Select v-model="params.type" transfer placeholder="类型" clearable style="width: 120px;">
            <Option v-for="(item,index) in checkType" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="服务子类型" prop="subType">
          <Select v-model="params.subType" transfer placeholder="子类型" clearable style="width: 200px;">
            <Option v-for="(item,index) in subTypeList" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="请输入" style="width: 120px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="detail">
        <inspectsDetail :mid="tableSelectItem.length===1?tableSelectItem[0].checkId:''" v-if="subPlanType===5"></inspectsDetail>
        <checkDetail :mid="tableSelectItem.length===1?tableSelectItem[0].checkId:''" v-else></checkDetail>
      </template>
    </ys-page-list>
    <ys-modal v-model="azxFlag" :width="900" :title="subPlanType === 5 ? '新建验收任务' : '新建检查任务'">
      <!-- 验收任务 -->
      <inspectsEdit @on-ok="taskEdit" :midData="tableSelectItem" :type="subPlanType" v-if="subPlanType===5"></inspectsEdit>
      <!-- 安责险/专项任务 -->
      <azxEdit @on-ok="taskEdit" :planData="tableSelectItem" :type="subPlanType" :subType="subPlanSubType" v-else></azxEdit>
    </ys-modal>
    <ys-modal v-model="checkFlag" :width="1000" title="关联服务">
      <!-- 验收任务 -->
      <inspects :policyId="tableSelectItem.length===1 ? tableSelectItem[0].policyId : ''" @on-ok="getData" v-if="subPlanType===5"></inspects>
      <!-- 安责险/专项 -->
      <MyCheck :type="subPlanType" :subType="subPlanSubType" @on-ok="getData" :policyId="tableSelectItem.length===1 ? tableSelectItem[0].policyId : ''" v-else></MyCheck>
    </ys-modal>
  </div>
</template>
<script>
import pageList from '@/mixins/pageList.js'
import checkDetail from "@/views/task/detail.vue";
import inspectsDetail from "@/views/inspects/detail.vue";
import azxEdit from "@/views/task/edit.vue";
import inspectsEdit from "@/views/inspects/edit.vue";
import {checkSelectExpert} from '@/api/check'
import {subPLanUnSelectCheck, subPLanUnSelectInspects, bindPlan} from "@/api/plan";
import MyCheck from "_c/MyCheck";
import inspects from "_c/inspects";

export default {
  name: '',
  mixins: [pageList],
  props: {
    policyID: {
      type: [Number, String],
      default: null
    },
    modData: {
      type: Array,
      default: () => []
    },
    FinishStartAt: {
      type: String,
      default: ''
    },
    FinishEndAt: {
      type: String,
      default: ''
    },
    noCheck: { // 是否关联任务，1 为未关联
      type: Number,
      default: null
    }
  },
  components: {
    checkDetail,
    inspectsDetail,
    azxEdit,
    inspectsEdit,
    MyCheck,
    inspects,
  },
  data() {
    return {
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {width: 120, title: '类型', key: 'typeName', align: 'center'},
        {width: 200, title: '服务子类型', key: 'subTypeName', align: 'center'},
        {minWidth: 220, title: '子计划名称', key: 'title', align: 'left'},
        {minWidth: 200, title: '项目名', key: 'projectName', align: 'left'},
        {minWidth: 200, title: '投保机构', key: 'enterpriseName', align: 'left'},
        {minWidth: 80, title: '服务费(元)', key: 'costPoint', align: 'left'},
        {width: 110, title: '总计划审核状态', key: 'status', align: 'center', renderConfig: {type: 'baseData', parentName: '保单总计划状态'}},
        {width: 120, title: '开始日期', key: 'startAt', align: 'left', renderConfig: {type: 'formatDate'}},
        {width: 120, title: '结束日期', key: 'finishAt', align: 'left', renderConfig: {type: 'formatDate'}},
        {
          width: 100, title: '关联任务', key: 'checkId', align: 'center', render: (h, params) => {
            return h('span', params.row.checkId > 0 ? '已关联' : '未关联');
          }
        },
        {minWidth: 120, title: '组长', key: 'leaderName', align: 'left'},
        {
          minWidth: 200, title: '其他专家', key: 'expertList', align: 'center',
          render: (h, params) => {
            return h('span', (params.row.expertList || []).map((item) => item.iname).join('，'));
          }
        }
      ],
      checkFlag: false,
      azxFlag: false,
      params: {
        haveCheck: '',
        type: '',
        orgId: '',
        servePlanId: '',
        keyword: '',
        startAt: '',
        endAt: '',
        noArea: true,
        isTop: false,
        statusList: [20], // 只显示审核通过的计划
      },
      form: {
        checkId: '',
        eventPolicyId: '',
        id: '',
      }
    }
  },
  computed: {
    subTypeList() {
      const type = this.params.type
      let data = this.checkType.find(item => item.value === type)
      return data?.children || [];
    },
    subPlanType() {
      return this.tableSelectItem.length === 1 ? this.tableSelectItem[0].type : null
    },
    subPlanSubType() {
      return this.tableSelectItem.length === 1 ? this.tableSelectItem[0].subType : null
    },
    multiBtn() {
      let data = this.checkType.find(item => item.value === (this.tableSelectItem[0]?.type || ''))
      let children = data?.children || [];
      const selectData = this.tableSelectItem[0]
      return [
        {
          hiddenBtnIcon: true,
          children: [
            {
              type: 'detail',
              title: '查看服务',
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1 || selectData.checkId === 0,
            },
            {
              // 只有审核通过的任务才可操作与任务相关的功能
              click: () => this.checkFlag = true,
              title: '关联服务',
              icon: 'ios-lock',
              disabled: this.tableSelectItem.length !== 1 || this.userInfo.expert.id !== selectData.leaderId || selectData.checkId > 0 || this.tableSelectItem[0].status !== 20 || (!!this.tableSelectItem[0]?.subType && children.length === 0),
            },
            {
              click: () => this.unboundTask(),
              title: '解绑服务',
              icon: 'md-key',
              disabled: this.tableSelectItem.length !== 1 || this.userInfo.expert.id !== selectData.leaderId || selectData.checkId <= 0 || this.tableSelectItem[0].status !== 20,
            },
            {
              click: () => this.azxFlag = true,
              title: '新建服务',
              icon: 'ios-add-circle',
              disabled: this.tableSelectItem.length !== 1 || selectData.checkId > 0 || this.userInfo.expert.id !== selectData.leaderId || this.tableSelectItem[0].status !== 20 || (!!this.tableSelectItem[0]?.subType && children.length === 0),
            },
          ]
        }
      ]
    },
  },
  watch: {
    'params.type'() {
      this.$nextTick(() => {
        this.params.subType = ''
      })
    }
  },
  mounted() {
    this.params.orgId = this.userInfo.org_id;
    this.params.servePlanId = this.mid;
    this.params.policyID = this.policyID;
    this.params.FinishStartAt = this.FinishStartAt
    this.params.FinishEndAt = this.FinishEndAt
    this.params.noCheck = this.noCheck
    this.getList();
  },

  methods: {
    getData(data) {
      this.form.checkId = data[0].id;
      this.form.eventPolicyId = data[0].id;
      this.form.id = this.tableSelectItem[0].id;
      let bindUrl = ''
      switch (this.type) {
        case 5:
          bindUrl = '/servePlan/subPlan/SelectInspects'
          break;
        default:
          bindUrl = '/servePlan/subPlan/SelectCheck'
          break;
      }
      bindPlan(bindUrl, this.form).then(res => {
        if (res.code === 200) {
          this.$Notice.success({
            title: '关联成功！'
          });
          this.getList();
        }
      })
    },
    unboundTask() {
      this.$Modal.confirm({
        title: '确定解绑当前服务？',
        onOk: async () => {
          let unBind
          if(this.subPlanType === 5) {
            unBind = () => subPLanUnSelectInspects({id: this.tableSelectItem[0].id})
          } else {
            unBind = () => subPLanUnSelectCheck({id: this.tableSelectItem[0].id})
          }
          unBind().then(res => {
            if (res.code === 200) {
              this.$Notice.success({
                title: '解绑成功！'
              });
              this.getList()
            }
          });
        },
      });
    },
    async taskEdit(data, nextType) {
      await this.getList()
      // 触发保存并发布按钮，待请求完列表数据后获取到被点击编辑最新计划数据，调用
      if (nextType === 'saveAndRelease') {
        checkSelectExpert({id: data.checkId || data.id, noExpert: true, serveTime: data.startAt || data.serveTime}).then(res => {
          if (res.code === 200) {
            this.$Notice.success({
              title: '发布成功！'
            });
          } else {
            this.$Notice.error({
              title: '发布失败！',
            });
          }
        })
      }
    }
  }
}
</script>
