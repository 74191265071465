<template>
  <div>
    <ys-detail :icon="detailIcon" :loading="loading" :name="detail.iname">
      <Tag size="medium" color="blue" slot="status">{{ detail.status|getBaseDataName('抽查任务状态') }}</Tag>
      <template slot="brief">
        <ys-cell title="任务编号" :label="detail.number" style="word-break: break-all;"/>
        <ys-cell title="计划服务日期" :label="detail.serveTime|formatDate"/>
        <ys-cell title="完成截止日" :label="detail.finishAt|formatDate"/>
        <ys-cell title="备注" :label="detail.remarks" style="word-break: break-all;"/>
      </template>
      <template slot="main-left">
        <Tabs type="card" v-model="tab" :key="detail.id">
          <Tab-pane label="任务信息" icon="ios-paper" name="1">
            <Info :midData="[detail]" v-if="tab==='1'"></Info>
          </Tab-pane>
          <Tab-pane label="隐患列表" icon="md-list-box" name="2">
            <problems :tableHeight="pageListHeight" :midData="[detail]" :mid="detailID" v-if="tab==='2'"></problems>
          </Tab-pane>
          <Tab-pane label="负责专家" icon="md-list-box" name="4">
            <ExpertListData :tableHeight="pageListHeight" :mid="detailID" :midData="[detail]" v-if="tab==='4'"></ExpertListData>
          </Tab-pane>
          <Tab-pane label="抽查项目" icon="ios-contacts" name="5">
            <PolicyData :tableHeight="pageListHeight" :mid="detailID" :midData="[detail]" v-if="tab==='5'"></PolicyData>
          </Tab-pane>
          <Tab-pane label="检查资料" name="6" icon="md-list-box" v-if="detail.hasDoc === 1">
            <Inspection :tableHeight="pageListHeight" :mid="detailID" :midData="[detail]" v-if="tab==='6'"></Inspection>
          </Tab-pane>
          <Tab-pane label="历史隐患" icon="md-list-box" name="3">
            <problems-task :tableHeight="pageListHeight" :midData="[detail]" v-if="tab==='3'"></problems-task>
          </Tab-pane>
          <Tab-pane label="相关附件" name="7" icon="md-document">
            <ys-resource :resourceType="24" :tableHeight="pageListHeight" :mid="detailID" v-if="tab==='7'"></ys-resource>
          </Tab-pane>
        </Tabs>
      </template>
      <template slot="main-right">
        <h1 class="h1Title">其他信息</h1>
        <div style="text-align: center">暂未开放！</div>
      </template>
    </ys-detail>
  </div>
</template>

<script>
import detail from '@/mixins/detail.js'
import {spotGetInfo} from "@/api/spotCheckTask";

export default {
  name: '',
  mixins: [detail],
  components: {
    'Info': () => import('@/views/spotCheckTask/detail/baseInfo.vue'),
    'ExpertListData': () => import('@/views/spotCheckTask/detail/expertListData.vue'),
    'PolicyData': () => import('@/views/spotCheckTask/detail/policyData.vue'),
    'problems': () => import('@/views/spotCheckTask/detail/problems/index.vue'),
    'problemsTask': () => import('@/views/spotCheckTask/detail/problems/task.vue'),
    'Inspection': () => import('@/views/spotCheckTask/detail/Inspection/index.vue'),
  },
  data() {
    return {
      tab: '1',
    }
  },
  computed: {},
  created() {},
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.loading = true;
      spotGetInfo({id: this.detailID, random: new Date().getTime()}).then((res) => {
        this.loading = false
        this.detail = res.data;
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped lang="less">

</style>