<template>
  <div class="settingForm">
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="100" style="width: 400px;">
      <FormItem label="原密码" prop="Pass" v-if="!mid">
        <Input type="password" v-model="form.Pass" placeholder="请输入原密码"></Input>
      </FormItem>
      <FormItem label="新密码" prop="New_pass">
        <Input type="password" v-model="form.New_pass" placeholder="请输入新密码"></Input>
      </FormItem>
      <FormItem label="重复新密码" prop="Re_pass">
        <Input type="password" v-model="form.Re_pass" placeholder="请输入新密码"></Input>
      </FormItem>
      <FormItem>
        <Button type="primary" @click="ok('user/EditPwd')">保存</Button>
      </FormItem>
    </Form>
  </div>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex'
import editMixins from '@/mixins/edit'; //引入混入组件edit

export default {
  name: '',
  mixins: [editMixins],
  computed: {
    ...mapGetters(['userInfo'])
  },
  data() {
    return {
      ruleValidate: {
        Pass: [
          {required: true, message: '原密码不能为空！', trigger: 'blur'}
        ],
        New_pass: [
          {required: true, message: '新密码不能为空！', trigger: 'blur'}
        ],
        Re_pass: [
          {
            required: true, trigger: 'blur', validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请再次输入密码!'));
              } else if (value !== this.form.New_pass) {
                callback(new Error('两次密码输入不一致，请确认!'));
              } else {
                callback();
              }
            }
          },
        ]
      },
      form: {
        Id: '',
        New_pass: '',
        Pass: '',
        Re_pass: '',
      },
    }
  },

  mounted() {
    this.form.Id = this.mid || this.userInfo.id;
  },
  methods: {
    ...mapMutations(['setToken', 'setUserInfo']),
    ok(url, method) {
      this.modal.spinShow = true;
      this.$refs['form'].validate(valid => {
        if (valid) {
          this[method || '$post'](url, this.form).then((res) => {
            this.modal.spinShow = false;
            if (res.code === 200) {
              this.$Modal.warning({
                title: '提示',
                content: '密码修改成功，请重新登录！',
                onOk: () => {
                  this.setToken();
                  this.setUserInfo();
                  sessionStorage.clear();
                  this.$router.push({name: 'login'})
                }
              });
            }
          }).catch(() => {
            this.modal.spinShow = false;
          })
        } else {
          this.$Notice.error({
            title: "操作失败！",
            desc: '请检查必填项！'
          });
          this.modal.spinShow = false;
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
.settingForm {
  padding: 30px;
  overflow: auto;
}
</style>