<template>
  <ys-detail :icon="detailIcon" :loading="loading" :name="detail.iname">
    <Tag size="medium" color="blue" slot="status">{{ detail.status|getBaseDataName('项目进度') }}</Tag>
    <template slot="brief">
      <ys-cell title="施工许可证号" :label="detail.licenseNum"/>
      <ys-cell title="项目所在地" :label="detail.address"/>
      <ys-cell title="行业类型" :label="detail.industryType|getBaseDataName('行业类型')"/>
      <ys-cell title="工程类型" :label="detail.category|getBaseDataName('项目类型')"/>
    </template>
    <template slot="main-left">
      <Tabs v-model="tab" name="detail" type="card" :key="detail.id">
        <Tab-pane label="项目信息" icon="ios-paper" name="1">
          <base-info :mid="detailID" :detail="detail" v-if="tab==='1'"/>
        </Tab-pane>
        <Tab-pane label="参建单位" icon="md-contact" name="3">
          <Corp :tableHeight="pageListHeight" :mid="detailID" v-if="tab==='3'"/>
        </Tab-pane>
        <Tab-pane label="安责险服务" icon="ios-albums" name="5">
          <task :type="null" :tableHeight="pageListHeight" :projectId="detailID" v-if="tab==='5'"></task>
        </Tab-pane>
        <Tab-pane label="单体建筑" name="6" v-if="detail.category === '01'" icon="ios-hammer">
          <single-building :tableHeight="pageListHeight" :mid="detailID" v-if="tab==='6'"></single-building>
        </Tab-pane>
      </Tabs>
    </template>
    <template slot="main-right">
      <h1 class="h1Title">其他信息</h1>
      <div v-if="!map" style="text-align: center">暂未设置地理位置！</div>
      <div id="container"></div>
    </template>
  </ys-detail>
</template>

<script>
import detailMixins from '@/mixins/detail'
import {projectInfo} from "@/api/projects";
import BaseInfo from "@/views/projects/detail/baseInfo.vue";
import Corp from "@/views/projects/detail/corp/index.vue";
import task from "@/views/task/index.vue";
import SingleBuilding from "@/views/projects/detail/building/SingleBuilding.vue"

export default {
  name: "detail",
  mixins: [detailMixins],
  components: {
    BaseInfo,
    Corp,
    task,
    SingleBuilding,
  },
  data() {
    return {
      map: null,
    }
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    initMap() {
      //初始化地图
      // eslint-disable-next-line
      this.map = new TMap.Map("container", {
        zoom: 16,//设置地图缩放级别
        mapStyleId: 'style 1 墨渊',
      });
    },
    getDetail() {
      this.loading = true;
      projectInfo({id: this.detailID}).then(res => {
        this.loading = false;
        this.detail={};
        if (res.code === 200) {
          this.detail = res.data;
          this.setTagName(this.detail.iname);
          if (this.detail.lat && this.detail.lng) {
            if (!this.map) this.initMap();
            // eslint-disable-next-line
            this.map.setCenter(new TMap.LatLng(this.detail.lat, this.detail.lng))
            // eslint-disable-next-line
            new TMap.InfoWindow({
              map: this.map,
              // eslint-disable-next-line
              position: new TMap.LatLng(this.detail.lat, this.detail.lng), // eslint-disable-next-line
              content: "<font color='#000000'>" + this.detail.address + "</font>" //设置信息框内容
            });
          } else {
            this.map ? this.map.destroy() : '';
            this.map = null
          }
        }
      })
    }
  }
}
</script>

<style scoped>
#container {
  width: 100%;
  height: 500px;
}
</style>