<template>
  <div class="home">
    <ys-page-list
        ref="table"
        action="/complaint/List"
        :params="params"
        :multiBtn="multiBtn"
        :tableHeight="resultHeight"
        :multiColumn="true"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="状态" prop="status">
          <Select v-model="params.status" placeholder="状态" style="width: 120px;" clearable>
            <Option v-for="(item,index) in baseData['投诉建议状态']" :value="item.value" :key="index">{{ item.name }}
            </Option>
          </Select>
        </FormItem>
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" style="width: 180px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add">新增</Button>
        </ButtonGroup>
      </template>
      <template slot="detail">
        <Detail :mid="detailID"></Detail>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="500" title="新增投诉建议">
      <Edit :mid="detailID" @on-ok="getList"></Edit>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js';
import Edit from "@/views/complaint/edit.vue";
import Detail from "@/views/complaint/detail.vue";

export default {
  name: 'train',
  mixins: [pageList],
  components: {Edit,Detail},
  data() {
    return {
      params: {
        type: '',
        status: '',
        isTop: false,
        noArea: true,
        keyword: '',
      },
      finishShow: false,
      headerColumns: [
        {width: 50, type: 'selection', title: '选择框', align: 'center'},
        {width: 50, type: 'index', title: '序号', align: 'center'},
        {width: 230, title: '编号', key: 'number', align: 'center'},
        {width: 200, title: '标题', key: 'title', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {minWidth: 350, title: '内容', key: 'content', align: 'left', tooltip: true},
        {width: 80, title: '匿名', key: 'isAnonymous', align: 'center', renderConfig: {type: 'baseData', parentName: '是否'}},
        {width: 180, title: '提交时间', key: 'createdAt', align: 'center'},
        {width: 100, title: '状态', key: 'status', align: 'center', renderConfig: {type: 'baseData', parentName: '投诉建议状态'}},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          hiddenBtnIcon: true,
          children: [
            {
              type: 'detail',
              targetUrl: '/main/train/detail/' + this.detailID,
              title: '查看详情',
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        },
      ]
    }
  },
  watch: {},
  mounted() {
    this.getList();
  },
  methods: {}

}
</script>
