<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" :label-width="100" inline="">
      <FormItem label="检查结果" prop="result">
        <Select v-model="form.result " placeholder="检查结果">
          <Option v-for="(item,index) in baseData['抽查任务结果']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="操作人" prop="userName">
        <Input v-model="form.userName" placeholder="操作人" readonly></Input>
      </FormItem>
      <FormItem label="完成时间" prop="finishedAt">
        <ys-date-picker type="datetime" style="width: 100%" placeholder="完成时间" v-model="form.finishedAt"/>
      </FormItem>
      <FormItem label="抽查结论" prop="conclusion" style="width: 100%">
        <Input  v-model="form.conclusion" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows: 3,maxRows: 5}" placeholder="抽查结论"></Input>
      </FormItem>
      <FormItem label="附件" prop="fileList" style="width: 100%;text-align: center">
        <ys-upload
            @on-success="bLFile"
            :headers="{token:token}"
            ref="upload"
            type="halfList"
            :defaultList.sync="fileList"
            action="/web/resource/UploadFile">
        </ys-upload>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok('/spot/Finish')">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import edit from '@/mixins/edit'

export default {
  name: '',
  mixins: [edit],
  components: {},
  props: {},
  data() {
    return {
      ruleValidate: {
        completionAt: [
          {required: true, message: '请选择截止完成日', trigger: 'blur', type: 'string'}
        ],
      },
      fileList: [],
      form: {
        id: '',
        userName: '',
        finishedAt: new Date(),
        result: 1,
        conclusion: '',
        fileList: [],
      }
    }
  },
  computed: {},
  mounted() {
    this.form.id = this.mid
    this.form.userName = this.userInfo.name
    this.form.userId = this.userInfo.id
  },
  methods: {
    bLFile(data) {
      this.form.fileList = data.map((item) => item.id)
    }
  },
}
</script>

