<template>
  <task :type="1" keyID="E4AE84A5352D1812580450ADC067D99A"></task>
</template>

<script>
import Task from "@/views/task/index";
export default {
  name: "specialTask",
  components: {Task}
}
</script>

<style scoped>

</style>