import {post, get} from 'ys-admin'


/**
 *  验收任务详情
 * @param {Object} params
 * @returns {Object}
 */
export const inspectsInfo = function (params) {
  return get('/inspects/GetInfo', params)
};

/**
 *  接受验收任务
 * @param {Object} params
 * @returns {Object}
 */
export const acceptInspects = function (params) {
  return post('/inspects/Accept', params)
};

/**
 *  验收项附件上传
 * @param {Object} params
 * @returns {Object}
 */
export const inspectsItemEdit = function (params) {
  return post('/inspects/item/Edit', params)
};

/**
 *  验收项列表
 * @param {Object} params
 * @returns {Object}
 */
export const inspectsItemList= function (params) {
  return get('/inspects/item/List', params)
};

/**
 * 查询验收内容验收项
 * @param {Object} params
 * @returns {Object}
 */
 export const getInspectsList = function (params) {
  return get('/inspectsdict/List', params)
};

/**
 * 发布验收任务
 * @param {Object} params
 * @returns {Object}
 */
 export const publishInspects = function (params) {
  return post('/inspects/Commit', params)
};
