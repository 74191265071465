<template>
  <task :type="7" keyID="9B742AFA55B88E9B1BC3AD7A5802FBFA"></task>
</template>

<script>
import task from "@/views/task/index.vue";

export default {
  name: 'emergency',
  components: {task},
  data() {
    return {}
  },
  mounted() {
  },
  methods: {}
}
</script>