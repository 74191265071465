<template>
  <Card title="风险大项统计" :dis-hover="true" :bordered="false">
    <template #extra><span>仅显示前10个检查大项</span></template>
    <div :style="{height: (this.height - 85) / 2 + 'px'}">
      <Select v-model="riskItem" style="width:100%" @on-change="selectChange">
        <Option v-for="item in riskList" :value="item.id" :key="item.id">{{ item.iname }}</Option>
      </Select>
      <ys-empty v-if="option.radar.indicator.length === 0 || option.series[0].data.length === 0" title="暂无数据"></ys-empty>
      <div id="chart" style="height: 100%;width: 90%;"></div>
    </div>
  </Card>
</template>

<script>
import * as echarts from 'echarts';
import {mapGetters} from 'vuex';
import {getStandardData, workRiskCount} from '@/api/common.js'
export default {
  name: '',
  props: {},
  data() {
    return {
      riskItem: '',
      riskList: [],
      myChart: null,
      option: {
        tooltip: {
          trigger: 'item',
          confine: true
        },
        legend: {
          show: false,
        },
        radar: {
          shape: 'circle',
          center: ['50%', '50%'],
          radius: '60%',
          indicator: []
        },
        series: [
          {
            name: '隐患数（个）',
            type: 'radar',
            itemStyle: {
              color: "#5E81FF",
              borderColor: '#5E81FF',
            },
            areaStyle: {
              color: '#5E81FF'
            },
            symbolSize: 6,
            symbolKeepAspect: false,
            lineStyle: {
              type: "solid"
            },
            emphasis: {
              scale: false
            },
            label: {
              show: true,
              color: '#5E81FF',
            },
            data: []
          },
        ]
      },
    }
  },
  computed: {
    ...mapGetters(['height']),
  },
  mounted() {
    getStandardData({limit: -1, status: 1}).then((res) => {
      this.riskList = res.data.list || [];
      this.riskItem = this.riskList[0].id
      this.selectChange(this.riskList[0].id)
    })
    this.myChart = echarts.init(document.getElementById('chart'));
  },
  methods: {
    selectChange(item) {
      workRiskCount({id: item}).then(res => {
        if(res?.data) {
          // 不足3个出来的雷达图会变形,自动补足为三个
          if(res.data.length < 3) {
            let arr = JSON.parse(JSON.stringify(res.data))
            for(let i = 0;i < 3 - res.data.length;i ++) {
              arr.push({
                name: "无",
                value: 0
              })
            }
            res.data = arr
          }
          const max = Math.max(...res.data.map(item => item.value))
          res.data.forEach(item => {
            item.max = max
          });
          this.option.radar.indicator = res.data
          this.option.series[0].data = [{
            value: res.data.map(item => item.value)
          }]
        } else {
          this.option.radar.indicator = []
          this.option.series[0].data = []
          // this.$Notice.warning({
          //   title: '提示',
          //   desc: '当前检查项目下暂无数据'
          // });
        }
        this.setChart()
      })
    },
    setChart() {
      this.myChart.clear();
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
      this.myChart.setOption(this.option);
    }
  }
}
</script>

<style>

</style>