const QRCode = require('qrcode');
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'

/**
 * html生成pdf
 * 函数名 htmlToPdf
 * 参数  [title:Sring 文件名]、[pdfID:Sring 要导出的html元素]、[orientation:string 方向 可选l横向 p竖向]
 * 结果  返回pdf文件
 **/
export const htmlToPdf = (title, pdfID, orientation = 'l',picture=true) => {
  return new Promise((resolve, reject) => {
    if (!!window.ActiveXObject || "ActiveXObject" in window) {
      reject('截图打印暂不支持IE内核浏览器，请更换火狐或谷歌chrome内核浏览器，360等双核浏览器请切换至极速模式');
    }
    let element = document.querySelector(pdfID)
    if(picture){
      html2Canvas(element, {allowTaint: true, scale: 1.5}).then(function (canvas) {
          //获取canvas画布的宽高
          let contentWidth = canvas.width;
          let contentHeight = canvas.height;
          //一页pdf显示html页面生成的canvas高度;
          let pageHeight = orientation === 'l' ? contentWidth / 841.89 * 592.28 : contentWidth / 592.28 * 841.89;
          //未生成pdf的html页面高度
          let leftHeight = contentHeight;
          //页面偏移
          let position = 0;
          //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
          let imgWidth = orientation === 'l' ? 841.89 : 592.28;
          let imgHeight = (orientation === 'l' ? 841.89 : 592.28) / contentWidth * contentHeight;
          let pageData = canvas.toDataURL();
          let pdf = new JsPDF(orientation, 'pt', 'a4');
          //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
          //当内容未超过pdf一页显示的范围，无需分页
          if (leftHeight < pageHeight) {
            pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
          } else {
            while (leftHeight > 0) {
              pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
              leftHeight -= pageHeight;
              position -= orientation === 'l' ? (592.28) : 841.89;
              //避免添加空白页
              if (leftHeight > 0) {
                pdf.addPage();
              }
            }
          }
          pdf.save(title + '.pdf');
          resolve('success')
        }
      )
    }
  })
}

/**
 * 生成二维码
 * 函数名 showQrcode
 * 参数  [content:Sring]、[width:Numbebr]、[height:Numbebr]
 * 结果  promise对象  返回base64图片
 **/
export const showQrcode = (content = '', text, width, height, element) => {
  QRCode.toDataURL(
    content,
    {errorCorrectionLevel: "H"},
    (err, url) => {
      window.setTimeout(() => {
        if (!url) return;
        let imgElement = document.getElementById(element);
        let logoElement = document.getElementById('qrCodeLogo');
        imgElement.src = url;
        window.setTimeout(() => {
          let ctx = document.getElementById(element + '-canvas').getContext("2d");
          ctx.imageSmoothingEnabled = false;
          ctx.drawImage(imgElement, 0, 0, imgElement.width, imgElement.height);

          if (logoElement) {
            //设置logo大小
            //设置获取的logo将其变为圆角以及添加白色背景
            ctx.fillStyle = "#fff";
            ctx.beginPath();
            let logoPosition = (width - width / 5) / 2; //logo相对于canvas居中定位
            let h = logoElement.width + 10; //圆角高 10为基数(logo四周白色背景为10/2)
            let w = logoElement.width + 10; //圆角宽
            let x = logoPosition - 5;
            let y = logoPosition - 5;
            let r = 5; //圆角半径
            ctx.moveTo(x + r, y);
            ctx.arcTo(x + w, y, x + w, y + h, r);
            ctx.arcTo(x + w, y + h, x, y + h, r);
            ctx.arcTo(x, y + h, x, y, r);
            ctx.arcTo(x, y, x + w, y, r);
            ctx.closePath();
            ctx.fill();
            ctx.drawImage(logoElement, logoPosition, logoPosition, width / 5, width / 5);
          }
          if (text) {
            let temp = text.split(':::');
            //设置字体
            let tw = ctx.measureText(text).width; //文字真实宽度
            let ftop = height - 50; //根据字体大小计算文字top
            let fleft = (width - tw) / 2; //根据字体大小计算文字left
            let tp = 7; //字体边距为字体大小的一半可以自己设置
            ctx.fillStyle = "#fff";
            ctx.fillRect(
              fleft - tp / 2,
              ftop - tp / 2,
              tw + tp,
              12 + tp
            );
            ctx.font = "14px Microsoft YaHei";
            ctx.textBaseline = "top"; //设置绘制文本时的文本基线。
            ctx.fillStyle = "#333";
            for (let b = 0; b < temp.length; b++) {
              let tempW = ctx.measureText(temp[b]).width;
              ctx.fillText(
                temp[b],
                (width - tempW) / 2,
                ftop + (b) * 20
              );
            }
          }
        })
      }, 0)
    });
};
