<template>
  <div class="home">
    <ys-page-list
        ref="table"
        action="/inspects/item/List"
        :params="params"
        @on-selection-change="checkItem"
        :tableHeight="resultHeight+70"
        :multiBtn="multiBtn"
        :header="false"
        :headerColumns="headerColumns">
      <template slot="search"></template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="600" title="查看附件">
      <inspects-file :mid="detailID" @on-ok="getList"></inspects-file>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'

export default {
  name: 'problems',
  mixins: [pageList],
  props: {
    status: {type: Number, default: null}
  },
  components: {
    inspectsFile: () => import('@/views/inspects/detail/inspectsFile')
  },
  data() {
    return {
      params: {
        inspectId: '',
      },
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {minWidth: 230, title: '验收项目', key: 'name', align: 'left'},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              click: () => this.modShow = true,
              title: '查看附件',
              icon: 'md-contact',
              disabled: this.tableSelectItem.length !== 1||this.status!==40
            },
          ]
        },
      ]
    }
  },
  mounted() {
    this.params.inspectId = this.mid;
    this.getList()
  }
}
</script>
