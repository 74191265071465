<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="120" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <FormItem label="机构名称" prop="iname">
        <Input v-model="form.iname" placeholder="机构名称">
          <Button slot="append" @click="bindOrg=true">选择机构</Button>
        </Input>
      </FormItem>
      <FormItem label="社会信用代码" prop="societyCode">
        <Input v-model="form.societyCode" disabled placeholder="社会信用代码"></Input>
      </FormItem>
      <FormItem label="参建单位类型" prop="type">
        <Select v-model="form.type" transfer placeholder="参建单位类型">
          <Option v-for="(item,index) in baseData['参建单位类型']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="办公地址" prop="address">
        <Input v-model="form.address" disabled placeholder="项目地址"></Input>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok('/projects/org/LinkProject')">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
    <ys-modal v-model="bindOrg" :width="1200" title="绑定机构">
      <org @on-ok="getOrg"></org>
    </ys-modal>
  </div>
</template>

<script>
import edit from '@/mixins/edit'

export default {
  name: 'projectEdit',
  mixins: [edit],
  components: {
    'org': () => import('_c/org.vue'),
  },
  data() {
    return {
      ruleValidate: {
        iname: [
          {required: true, message: '请选择机构', trigger: 'chang'}
        ],
      },
      bindOrg:false,
      form: {
        id: '',
        iname: '',
        address: '',
        societyCode: '',
        oType: 4,
        type: 1,
        projectId: '',
        parentId: '',
        orgId: '',
      },
    }
  },
  mounted() {
    this.form.projectId = this.parentID;
  },
  methods: {
    getOrg(data) {
      this.form.iname = data[0].iname;
      this.form.id = data[0].id;
      this.form.address = data[0].address;
      this.form.type = data[0].type;
      this.form.societyCode = data[0].societyCode;
      this.form.orgId = data[0].id;
    },
  }
}
</script>

<style lang="less" scoped>
</style>
