<template>
  <div>
    <ys-detail :icon="detailIcon" :loading="loading" :name="detail.checkName">
      <Tag size="medium" color="blue" slot="status">{{ detail.checkStatus|getBaseDataName('专项任务状态') }}</Tag>
      <template slot="brief">
        <ys-cell title="项目名称" :label="detail.projectName"/>
        <ys-cell title="打卡时间" :label="detail.signTime"/>
        <ys-cell title="打卡地址" :label="detail.address"/>
        <ys-cell title="打卡经纬度" :label="detail.signLat+','+detail.signLng"/>
      </template>
      <template slot="main-left">
        <Tabs type="card" v-model="tab">
          <Tab-pane label="打卡位置" icon="md-pin" name="1">
            <div id="container" :style="{height:height-215+'px',margin:'10px 0'}"></div>
          </Tab-pane>
        </Tabs>
      </template>
      <template slot="main-right">
        <h1 class="h1Title">打卡图片</h1>
        <viewer :images="detail.fileList">
          <template v-for="fileItem in detail.fileList">
            <ys-image :width="400" :height="400" :src="config.baseURL + '/resource/GetFile/'+fileItem.id" :key="fileItem.id"></ys-image>
          </template>
        </viewer>
      </template>
    </ys-detail>
  </div>
</template>

<script>
import detail from '@/mixins/detail.js'
import {checkRecordInfo} from "@/api/expert";
import config from "@/config";
import {transformGCJ2WGS} from "../../../../ys-admin/src/lib/transformGCJ2WGS";

export default {
  name: '',
  mixins: [detail],
  data() {
    return {
      detail: {},
      dictList: [],
      modShow: false,
      map: '',
      loading: false,
    }
  },

  mounted() {
    this.getDetail();
  },

  computed: {
    config() {
      return config
    }
  },

  methods: {
    getDetail() {
      if(this.map) this.map = null;
      this.loading = true;
      this.$all([
        checkRecordInfo({id: this.$router.currentRoute.params.id || this.mid}),
      ]).then((res) => {
        this.loading = false
        this.detail = res[0].data;
        this.setTagName(this.detail.iname);
        setTimeout(() => {
            // 转换坐标
          const data = transformGCJ2WGS(Number(this.detail.signLat), Number(this.detail.signLng))
          const lat = Number(data.lat);
          const lng = Number(data.lon);
          const center = /* eslint-disable */ new T.LngLat(lng, lat);
          this.map = /* eslint-disable */ new T.Map('container')
          this.map.centerAndZoom(center, 16);
          let infoWindow = /* eslint-disable */ new T.InfoWindow("<div><font color='#000000'>打卡地点：" + this.detail.address + "</font></div>");
          this.map.openInfoWindow(infoWindow, center); // 开启信息窗口
          this.loading = false
        }, 1000)
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped lang="less">
#container {
  width: 100%;
  height: 500px;
}

.main {
  display: flex;

  .mainLeft {
    width: 70%;
    padding: 8px 20px 0 20px;
    border-right: 10px solid #f4f4f4;
  }

  .mainRight {
    width: 30%;
    padding: 8px 20px 0 20px;
  }
}
</style>