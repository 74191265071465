<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" inline :label-width="90" :style="{height:height-200+'px',overflowY:'auto'}">
      <FormItem label="风险项目" prop="dictIds" style="width: 100%">
        <Cascader :data="dictList" :clearable="false" @on-change="dictIdChange" v-model="form.dictIds"></Cascader>
        <Alert type="warning" class="existProblems" v-if="problemsList.length>0">
          <h4 class="title">{{ form.itemName }} 已提交的风险{{ problemsList.length }}记录</h4>
          <ul>
            <li v-for="(item,index) in problemsList" :key="item.id" @click="showDetail(index)">
              <div class="item itemName">
                ({{ item.riskGrade|getBaseDataName('风险等级') }}) {{ item.itemName }}
              </div>
              <div class="item userName">专家：{{ item.expertName }}</div>
              <div class="item addTime">时间：{{ item.serveTime|formatDate('yyyy-MM-dd') }}</div>
              <div class="item">
                <Icon type="ios-arrow-forward"/>
              </div>
            </li>
          </ul>
        </Alert>
      </FormItem>
      <FormItem label="风险辨别" prop="itemDetailList" style="width: 100%">
        <div v-if="form.itemId">
          <span v-if="detailLoading">加载中....</span>
          <CheckboxGroup v-model="form.itemDetailList" @on-change="itemDetailChange" v-else>
            <ul class="itemInfoList">
              <li v-for="(item) in dictItemList" :key="item.id">
                <Checkbox border :label="item.id"> {{ item.desc }}</Checkbox>
              </li>
            </ul>
          </CheckboxGroup>
        </div>
        <span v-else>请先选择风险项目</span>
      </FormItem>
      <FormItem label="风险等级" prop="riskGrade" style="width: 20%">
        <Select v-model="form.riskGrade" placeholder="风险等级">
          <Option v-for="(item,index) in riskGradeList" :style="{'color':item.color }" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="可能导致事故类型" :label-width="140" prop="accidentTypeList" :style="{width: hasScore===1?'60%':'80%'}">
        <Select v-model="form.accidentTypeList" :max-tag-count="4" multiple clearable placeholder="可能导致事故类型">
          <Option v-for="item in accidentTypeList.length ? accidentTypeList : baseData['可能导致事故类型']" :value="item.name" :key="item.id">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="风险描述" prop="remarksText" style="width: 100%">
        <Input v-model="form.remarksText" type="textarea" :autosize="{minRows: 2,maxRows: 5}" placeholder="风险描述"></Input>
      </FormItem>

      <FormItem label="风险依据" prop="remarksRisk" style="width: 100%">
        <Row>
          <Col span="22">
            <Input v-model="form.remarksRisk" type="textarea" :autosize="{minRows: 2,maxRows: 5}" style="width: 100%" placeholder="风险及评价依据"></Input>
          </Col>
          <Col span="2">
            <Button type="primary" style="width: 100%;height: 100%;" @click="remarksRisk">选择</Button>
          </Col>
        </Row>
      </FormItem>

      <FormItem label="发现时间" prop="serveTime" style="width: 33.3%">
        <ys-date-picker v-model="form.serveTime" style="width: 100%;" placeholder="风险发现时间"></ys-date-picker>
      </FormItem>
      <FormItem label="整改期限" prop="reformTerm" style="width: 33.3%;">
        <Select v-model="form.reformTerm" placeholder="整改期限">
          <Option v-for="(item,index) in baseData['整改期限']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="建议整改截止日" :label-width="130" prop="reformDate" style="width: 33.3%;">
        <ys-date-picker v-model="form.reformDate" style="width: 100%;" placeholder="建议整改截止日"></ys-date-picker>
      </FormItem>
      <!-- 暂时先去除抽查任务的整改建议功能 -->
      <!-- <FormItem label="整改建议" prop="remarksSuggest" style="width: 100%">
        <Row>
          <Col span="22">
            <Input v-model="form.remarksSuggest" type="textarea" :autosize="{minRows: 2,maxRows: 5}" placeholder="整改建议" style="width: 100%"></Input>
          </Col>
          <Col span="2">
            <Button type="primary" style="width: 100%;height: 100%;" @click="quickInfo">选择</Button>
          </Col>
        </Row>
      </FormItem> -->
      <FormItem label="附件" prop="fileList" style="width: 100%">
        <ys-upload :format="['jpg','jpeg','png','gif']" :headers="{token:token}" action="/web/resource/UploadFile" :defaultList.sync="fileList" type="halfList" @on-success="bLFile" :limit="0"></ys-upload>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="save">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
    <ys-drawer inner v-model="detailFlag">
      <Detail :inner="true" :width="60" :mid="problemID"></Detail>
    </ys-drawer>
    <ys-modal v-model="quickInfoShow" :width="1000" :title="'选择整改建议'">
      <QuickInfo :orgId="midData && midData.length > 0 ? midData[0].serveOrgId : ''" :itemId="form.itemId" :type="1" :status="1" :keyword="quickInfoKeyword" @on-ok="quickInfoData" dictItemAction="/spot/dict/detail/List"></QuickInfo>
    </ys-modal>
    <ys-modal v-model="remarksRiskShow" :width="1000" :title="'选择风险依据'">
      <RemarksRisk :orgId="midData && midData.length > 0 ? midData[0].serveOrgId : ''" :itemId="form.itemId" @on-ok="remarksRiskData"></RemarksRisk>
    </ys-modal>
  </div>
</template>

<script>
import edit from '@/mixins/edit';
import Detail from "./detail.vue";
import RemarksRisk from "@/views/spotCheckTask/detail/problems/remarksRisk.vue";
import QuickInfo from "@/components/quickInfo.vue";
import {uuid, getRecorderAryByDeep} from 'ys-admin'

export default {
  name: 'problemAdd',
  components: {
    Detail,
    QuickInfo,
    RemarksRisk,
  },
  props: {
    isScore: { // 是否需要扣分
      type: Boolean,
      default: true
    }
  },
  mixins: [edit],
  data() {
    return {
      remarksRiskShow: false,
      quickInfoShow: false,
      detailFlag: false,
      ruleValidate: {
        // 风险等级、可能导致的事故类型、风险描述、风险依据、整改建议、附件
        dictIds: [
          {type: 'array', required: true, message: '风险项目不能为空', trigger: 'change'}
        ],
        itemDetailList: [
          {type: 'array', required: true, message: '请先选择风险辨别项', trigger: 'change'}
        ],
      },
      dictList: [],
      problemID: null,
      problemsList: [],
      dictItemList: [],
      quickInfoList: [],
      detailLoading: false,
      fileList: [],
      accidentTypeList: [],
      form: {
        spotId: '',
        itemId: '',
        // scope: null, // 抽查任务无需分数
        id: '',
        reformTerm: 2,
        categoryName: '',
        remarksText: '',
        reasonDetailList: [],
        itemDetailList: [],
        serveTime: new Date(),
        reformDate: new Date(new Date().getTime() + 3 * 24 * 3600 * 1000),
        remarksRisk: '',
        remarksSuggest: '',
        riskGrade: 2,
        fileList: [],
        dictIds: [],
        accidentTypeList: [],
        accidentType: ''
      },
    }
  },
  computed: {
    riskGradeList() {
      return this.baseData['风险等级'].filter(item => {
        return this.midData[0]?.riskGrades.split(',').some(strItem => Number(strItem) === item.value)
      })
    },
    multiRisk() {
      return this.midData && this.midData.length === 1 ? this.midData[0].multiRisk : 1; /*1 可多选风险项目，2不允许多选*/
    },
    hasScore() {
      return this.isScore ? (this.midData && this.midData.length === 1 ? this.midData[0].hasScore : 1) : 2; /*1 有扣分体系，2无扣分体系*/
    },
    quickInfoKeyword() {
      return this.dictList && this.form.dictIds ? this.dictList.find(item => item.id === this.form.dictIds[0])?.name : ''
    }
  },
  async mounted() {
    this.form.spotId = this.midData[0].id
    // 风险项
    await this.$get('/spot/dict/GetInfo', {id: this.midData[0].id}).then(res => {
      const deepGetCascaderData = (data) => {
        data.forEach((item) => {
          item.value = item.id
          item.label = item.name;
          if (Array.isArray(item.children)) {
            deepGetCascaderData(item.children)
          }
        })
        return data
      }
      this.dictList = deepGetCascaderData(res.data.filter(item => item.enable === 1));
    })
    if (this.mid) {
      await this.getDetail()
      this.form.dictIds = getRecorderAryByDeep({targetData: this.dictList, targetValue: this.form.itemId});
    }
  },
  methods: {
    quickInfo() {
      if (!this.form.itemId) {
        this.$Notice.warning({
          title: '提示!',
          desc: '请先选择风险项目'
        });
      } else {
        this.quickInfoShow = true
      }
    },
    remarksRisk() {
      if (!this.form.itemId) {
        this.$Notice.warning({
          title: '提示!',
          desc: '请先选择风险项目'
        });
      } else {
        this.remarksRiskShow = true
      }
    },
    async getDetail() {
      await this.$get('/spot/comment/GetInfo', {id: this.mid}).then(res => {
        if (res.code === 200) {
          Object.keys(this.form).forEach((item) => {
            this.form[item] = res.data[item]
          })
          this.form.itemDetailList = (res.data.itemDetailList ? JSON.parse(res.data.itemDetailList) : []).map((item) => item.id);
          this.fileList = res.data.fileList || [];
          this.form.fileList = this.fileList.map((item) => item.id);
        }
      })
      await this.getDictItem();
      this.matching(this.dictItemList.filter(item => this.form.remarksText.split('；').indexOf(item.desc) !== -1))
      this.form.accidentTypeList = this.form.accidentType.split(',')
    },
    showDetail(index) {
      this.problemID = this.problemsList[index].id;
      this.detailFlag = true;
    },
    async dictIdChange(value, selectedData) {
      this.form.itemId = value.pop();
      this.form.itemName = selectedData.pop().name;
      this.form.categoryName = selectedData.shift().name;
      this.form.itemDetailList = [];
      // 选择完风险项目后请求一下对应列表查看一下当前所选风险,是否已存在被选中
      await this.$get('/spot/comment/List', {spotId: this.parentID, itemId: this.form.itemId, limit: 0}).then(res => {
        if (res.code === 200) {
          this.problemsList = res.data.list;
        }
      })
      await this.getDictItem();

    },
    // 风险辨别扣分项
    async getDictItem() {
      this.detailLoading = true
      this.dictItemList = [];
      await this.$get('/spot/dict/detail/List', {itemId: this.form.itemId}).then(res => {
        if (res.code === 200) {
          this.dictItemList = res.data || [];
        }
        this.detailLoading = false;
      })
    },
    itemDetailChange(data) {
      // 初始话
      this.form.remarksText = '';
      this.form.remarksSuggest = ''
      this.form.accidentTypeList = []
      this.form.remarksRisk = ''
      this.form.reasonDetailList = []
      this.accidentTypeList = this.baseData['可能导致事故类型']
      // 数据依次拼接选中
      if (data.length >= 1) {
        if (this.multiRisk === 2) this.form.itemDetailList = [data[data.length - 1]]
        let checkData = this.dictItemList.filter((item) => this.form.itemDetailList.indexOf(item.id) >= 0);
        // 风险等级默认选中
        this.form.riskGrade = checkData[checkData.length - 1]?.riskGrade || this.riskGradeList[0]?.value
        if (checkData.length >= 1) {
          this.form.remarksText = checkData.map(item => item.desc).join('；');
          this.matching(checkData)
        }
      }
    },
    // 从已选数据中将相应数据找出来
    matching(dataList) {
      let accidentTypeList = []
      let reasonItemList = []
      dataList.forEach(item => {
        if (item.accidentType.length) {
          if (!accidentTypeList.some(typeItem => typeItem.name === item.accidentType)) {
            accidentTypeList.push(...(item.accidentType.split(/[\n\s+,，]/g).filter(item => item).map(typeItem => {
              return {name: typeItem, value: typeItem, id: uuid()}
            })))
          }
        }
        // 当前选中的风险依据（可能会存在运维录入重复数据，将重复数据过滤掉）
        if (item.remarks.length && !reasonItemList.some(itm => itm.desc === item.remarks)) {
          reasonItemList.push({id: uuid(), desc: item.remarks})
        }
        // 整改建议
        if (item.remarksSuggest.length && this.form.remarksSuggest.indexOf(item.remarksSuggest) === -1) {
          this.quickInfoData([{desc: item.remarksSuggest}])
        }
      })
      // 默认全部选中可能导致的事故类型
      this.accidentTypeList = accidentTypeList.length > 0 ? accidentTypeList : this.accidentTypeList
      this.form.accidentTypeList = accidentTypeList.length > 0 ? this.accidentTypeList.map(item => item.value) : []
      // 默认有携带对应风险依据时全部选中风险依据
      if (reasonItemList.length > 0) {
        this.form.reasonDetailList = reasonItemList.map(item => item.desc)
        this.remarksRiskData(reasonItemList)
      }
    },
    // 
    remarksRiskData(data) {
      this.form.remarksRisk = '';
      let risk = '依据：' + (this.midData.length === 1 ? this.midData[0]['warehouseName'] : '《建筑施工安全检查标准》JGJ59') + '-' + this.form.categoryName + (data[0]?.remarks ? '第' + data[0].remarks : '') + '：'
      data.forEach(item => {
        if (item.desc.length >= 1) {
          // 判断是否含有其他标点符号
          const SymbolList = [';', '；', '。', '.', '、', ':', '：', ',', '，']
          if (SymbolList.some(SymbolItem => SymbolItem === item.desc.slice(-1))) {
            item.desc = item.desc.substr(0, item.desc.length - 1)
          }
          this.form.remarksRisk = this.form.remarksRisk + item.desc.replace(/[\r\n]/g, "") + '；';
        }
      })
      this.form.remarksRisk = risk + this.form.remarksRisk;
    },
    // 拼接整改建议
    quickInfoData(data) {
      this.form.remarksSuggest = ''
      data.forEach(item => {
        this.form.remarksSuggest += `${item.desc.replace(/[\r\n]/g, "")}；`
      })
    },
    bLFile(data) {
      this.form.fileList = data.map((item) => {
        return item.id
      });
    },
    save() {
      this.form.accidentType = this.form.accidentTypeList.join(',')
      this.ok(this.form.id ? '/spot/comment/Edit' : '/spot/comment/Add')
    }
  }

}
</script>
<style scoped lang="less">
.itemInfoList {
  display: flex;
  flex-wrap: wrap;
  margin-top: -8px;

  li {
    margin-top: 8px;
    list-style: none;
  }
}

.existProblems {
  margin-top: 10px;
  margin-bottom: 0;
  padding-right: 8px;
  padding-bottom: 0;

  h4.title {
    padding: 3px 0 10px 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  ul {
    padding: 5px 0;

    li {
      list-style: none;
      display: flex;
      padding: 8px 0;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }

      .item {
        padding: 0 3px;
        cursor: pointer;
      }

      .itemName {
        flex: 1;
      }
    }
  }
}
</style>