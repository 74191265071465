<template>
  <div>
    <ys-detail :loading="loading" :name="detail.title">
      <Tag size="medium" color="blue" slot="status">{{ detail.status|getBaseDataName('投诉建议状态') }}</Tag>
      <template slot="brief">
        <ys-cell title="编号" :label="detail.number"/>
        <ys-cell title="匿名" :label="detail.isAnonymous|getBaseDataName('是否')"/>
        <ys-cell title="提交时间" :label="detail.createdAt"/>
        <ys-cell title="描述" :label="detail.content"/>
      </template>
      <template slot="main-left">
        <Tabs v-model="tab" type="card">
          <Tab-pane label="基本信息" icon="md-time" name="1">
            <base-info v-if="tab==='1'" :detailInfo="detail"></base-info>
          </Tab-pane>
        </Tabs>
      </template>
      <template slot="main-right">
        <h1 class="h1Title">反馈信息</h1>
        <log :mid="detail.id"></log>
      </template>
    </ys-detail>
  </div>
</template>

<script>
import detail from '@/mixins/detail.js'
import {mapGetters} from 'vuex'
import {complaintInfo} from "@/api/complaint";
import BaseInfo from "@/views/complaint/detail/baseInfo";
import log from "@/views/complaint/detail/log";

export default {
  name: '',
  mixins: [detail],
  components: {
    log,
    BaseInfo,
  },
  data() {
    return {
      detail: {},
      dictList: [],
      modShow: false,
      map: ''
    }
  },

  mounted() {
    this.getDetail();
  },

  computed: {
    ...mapGetters(['height'])
  },

  methods: {
    getDetail() {
      this.loading = true;
      complaintInfo({id: this.$router.currentRoute.params.id || this.mid}).then((res) => {
        if (res.code === 200) {
          this.loading = false
          this.detail = res.data;
        }
        this.setTagName(this.detail.iname);
      })
    }
  }
}
</script>

<style scoped lang="less">

</style>