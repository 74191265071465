<template>
  <div class="home">
    <ys-page-list
        ref="table"
        action="/comment/ListByProject"
        :params="params"
        :tableHeight="tableHeight"
        @on-selection-change="checkItem"
        :multiBtn="multiBtn"
        :header="header"
        :footer="footer"
        @on-ok="$emit('on-ok',$event)"
        :multiColumn="multiColumn"
        :detailInner="detailInner"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="风险等级" prop="riskGrade">
          <Select v-model="params.riskGrade" clearable placeholder="风险等级" style="width: 100px;">
            <Option v-for="(item,index) in baseData['风险等级']" :style="{'color':item.color }" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add" :disabled="midData.length===1&&(midData[0].status!==5||!!midData[0].amendPublishedAt)" v-if="mid">新增</Button>
        </ButtonGroup>
      </template>
      <template slot="detail">
        <Detail :mid="detailID"></Detail>
      </template>
    </ys-page-list>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList';
import Detail from "@/views/problems/detail.vue";

export default {
  name: 'problems',
  mixins: [pageList],
  props: {
    projectId: {type: [Number, String], default: null},
    riskGrade: {type: [Number], default: null},
  },
  components: {
    Detail,
  },
  data() {
    return {
      params: {
        spotId: '',
        riskGrade: '',
        projectId: '',
        commentAmendCommit: 1, // 只显示整改单已提交了的隐患
        keyword: '',
      },
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {minWidth: 150, title: '风险项目', key: 'itemName', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {width: 150, title: '所属检查项', key: 'categoryName', align: 'left'},
        {width: 100, title: '检查专家', key: 'expertName', align: 'center'},
        {minWidth: 150, title: '项目名', key: 'projectName', align: 'left'},
        {width: 250, title: '服务机构', key: 'serveOrgName', align: 'center'},
        {width: 110, title: '发现时间', key: 'serveTime', align: 'center', renderConfig: {type: 'formatDate'}},
        {
          width: 180, title: '风险辨别(扣分项)', key: 'scope', align: 'center', render: (h, params) => {
            return h('span', String(params.row.scope === -1 ? '-' : params.row.scope))
          }
        },
        {width: 150, title: '隐患整改状态', key: 'status', align: 'center', renderConfig: {type: 'baseData', parentName: '隐患状态'}},
        {width: 100, title: '风险等级', key: 'riskGrade', align: 'center', renderConfig: {type: 'baseData', parentName: '风险等级'}},
        {minWidth: 230, title: '风险描述', tooltip: true, key: 'remarksText', align: 'left'},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          hiddenBtnIcon: true,
          children: [
            {
              type: 'detail',
              title: '查看详情',
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        }
      ]
    }
  },
  mounted() {
    this.params.riskGrade = this.riskGrade || '';
    this.params.projectId = this.projectId || this.midData[0].project.id || '';
    this.getList()
  },
  methods: {}
}
</script>
