<template>
  <div id="specialTaskPie"></div>
</template>

<script>
import * as echarts from 'echarts';


export default {
  name: 'pie',
  props: {commentList: {type: Array, default: () => []}},
  data() {
    return {
      option: {
        title: {
          text: '本次安全检查风险分布',
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        series: [
          {
            name: '本次安全检查风险分布',
            type: 'pie',
            center: ['50%', '55%'],
            label: {
              formatter: '{name|{b}}\n{time|{c}个,{d}%}',
              minMargin: 5,
              edgeDistance: 10,
              lineHeight: 15,
              rich: {
                time: {
                  fontSize: 12,
                  color: '#999'
                }
              }
            },
            itemStyle: {
              borderRadius: 5
            },
            data: []
          }
        ]
      }
    }
  },
  mounted() {
    let chartDom = document.getElementById('specialTaskPie');
    chartDom.style.width = chartDom.getBoundingClientRect().width - 80 + 'px'
    let myChart = echarts.init(chartDom);
    let mapData = {};
    (this.commentList || []).forEach((item) => {
      if (!mapData[String(item.riskGrade)]) {
        mapData[String(item.riskGrade)] = {name: this.$getBaseDataName('风险等级', item.riskGrade, false), value: 0};
      }
      mapData[String(item.riskGrade)].value = mapData[item.riskGrade].value + 1
    })
    Object.keys(mapData).forEach((item) => {
      this.option.series[0].data.push(mapData[String(item)])
    })
    myChart.setOption(this.option);
  }
}
</script>
<style lang="less" scoped>

#specialTaskPie {
  height: 300px;
  width: 100% !important;
  text-align: center;
}
</style>
