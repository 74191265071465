<template>
  <div>
    <div v-if="loading" style="text-align: center;padding: 5px 0">
      <Icon type="ios-loading" class="demo-spin-icon-load"/>
      加载中......
    </div>
    <ul v-else class="approvalLine">
      <li class="node" v-for="(item) in List" :key="item.id">
        <div class="info">
          <div class="icon" :style="{backgroundColor:'#1065bf'}">
            <Icon type="md-code-working" size="22"/>
          </div>
          <div class="content">
            <p class="title">{{ item.title || '未知节点' }}</p>
            <p class="time">{{ item.createdAt || '待处理' }}</p>
          </div>
        </div>
        <div class="line">
          <div class="result">
            <p>说明：{{ item.desc || '' }}</p>
            <br>
            <div v-viewer="{url: 'data-src'}">
              <ys-image
                  :width="80"
                  :height="80"
                  :src="'/web/resource/GetFile/' + src.id + '?size=100'"
                  :data-src="'/web/resource/GetFile/' + src.id"
                  v-for="src in (item.fileList||[])"
                  :key="src.id">
              </ys-image>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import {complaintInfo} from "@/api/complaint";

export default {
  name: "info",
  props: {
    mid: {
      type: [Number, String], detail: null
    }
  },
  data() {
    return {
      List: [],
      loading: false,
    }
  },
  watch: {
    mid: function () {
      this.getDetail();
    }
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    async getDetail() {
      if (!this.mid) return;
      this.loading = true
      await complaintInfo({id: this.mid, noPending: true}).then(res => {
        if (res.code === 200) {
          this.List = res.data.complaintLog || [];
        }
      });
      this.loading = false;
    },
  }
}
</script>

<style scoped lang="less">
.approvalLine {

  .node {
    list-style: none;

    .info {
      display: flex;
      align-items: center;

      .icon {
        background-color: #0a69f4;
        width: 35px;
        height: 35px;
        line-height: 39px;
        color: #ffffff;
        border-radius: 300px;
        text-align: center;
      }

      .content {
        padding-left: 10px;
        text-align: left;

        p.time {
          font-size: 12px;
        }

      }

      .person {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .avatar {
          cursor: pointer;
          border-width: 2px;
          border-style: solid;
          border-color: transparent;
          margin-left: 3px;
          border-radius: 3px;
          overflow: hidden;
          width: 29.8px;
          height: 29px;
          text-align: center;

          & .active {
            box-shadow: 0 0 5px #0a69f4;
          }

        }
      }
    }

    .line {
      border-left: 2px solid #0a69f4;
      padding-left: 30px;
      margin: 5px 0 5px 20px;
      min-height: 20px;
      padding-bottom: 5px;

      .result {
        border: 1px solid #ececec;
        padding: 5px 10px;
        font-size: 12px;
        background-color: #fafafa;
      }

    }
  }
}

</style>