<template>
  <div class="home">
    <ys-page-list
        ref="table"
        :params="params"
        @on-selection-change="checkItem"
        :tableHeight="resultHeight"
        :multiBtn="multiBtn"
        :header="false"
        :footer="false"
        :multiColumn="false"
        :headerColumns="headerColumns"
        :tableData="expertList">
      <template slot="search"></template>
    </ys-page-list>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'

export default {
  name: 'problems',
  mixins: [pageList],
  props: {},
  components: {},
  data() {
    return {
      expertList: [],
      headerColumns: [
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {minWidth: 100, title: '姓名', key: 'name', align: 'left'},
      ]
    }
  },
  computed: {
    multiBtn() {
      return []
    }
  },
  mounted() {
    this.expertList = this.midData[0].expertList
  }
}
</script>
