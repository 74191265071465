<template>
  <div :style="{maxHeight:(height-180)+'px'}" style="overflow-y: auto" v-if="detailInfo.id>0">
    <ys-cell-group>
      <ys-cell title="标题" :label="detailInfo.title"/>
      <ys-cell title="编号" :label="detailInfo.number"/>
      <ys-cell title="匿名" :label="detailInfo.isAnonymous|getBaseDataName('是否')"/>
      <ys-cell title="提交时间" :label="detailInfo.createdAt"/>
      <ys-cell title="描述" block :label="detailInfo.content"/>
    </ys-cell-group>
    <h1 class="h1Title">相关附件</h1>
    <ys-file-list type="all" :gutter="3" :width="70" :height="70" :fileList="detailInfo.fileList||[]" ></ys-file-list>
  </div>
</template>

<script>
import baseInfo from "@/mixins/baseInfo";

export default {
  name: "baseInfo",
  mixins: [baseInfo],
  props: {detailInfo: Object},
}
</script>

<style scoped>

</style>