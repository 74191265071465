import {get, post} from 'ys-admin'

/**
 * 查询服务计划详情
 * @param {Object} params
 * @returns {Object}
 */
export const planInfo = function (params) {
  return get('/servePlan/GetInfo', params)
};

/**
 * 查询服务子计划列表
 * @param {Object} params
 * @returns {Object}
 */
export const subPlanList = function (params) {
  return get('/servePlan/subPlan/List', params)
};
/**
 * 关联服务
 * @param {Object} params
 * @param {String} url
 * @returns {Object}
 */
export const bindPlan = function (url,params) {
  return post(url, params)
};

/**
 * 查询服务子计划详情
 * @param {Object} params
 * @returns {Object}
 */
export const subPlanInfo = function (params) {
  return get('/servePlan/subPlan/GetInfo', params)
};

// /**
//  * 关联子计划
//  * @param {Object} params
//  * @returns {Object}
//  */
// export const bindCheck = function (params) {
//   return post('/servePlan/subPlan/SelectCheck', params)
// };

/**
* 查询总计划表
* @param {Object} params
* @returns {Object}
*/
export const servePlanList = function (params) {
  return get('/servePlan/List', params)
};

/**
* 子计划解绑安责险/专项任务
* @param {Object} params
* @returns {Object}
*/
export const subPLanUnSelectCheck = function (params) {
  return post('/servePlan/subPlan/UnSelectCheck', params)
};

/**
* 子计划解绑培训演练任务
* @param {Object} params
* @returns {Object}
*/
export const subPLanUnSelectEvent = function (params) {
  return post('/servePlan/subPlan/UnSelectEvent', params)
};

/**
* 子计划解绑验收任务
* @param {Object} params
* @returns {Object}
*/
export const subPLanUnSelectInspects = function (params) {
  return post('/servePlan/subPlan/UnSelectInspects', params)
};