<template>
  <div>
    <ys-page-list
        ref="table"
        :params="params"
        action="/projectBuilding/List"
        :tableHeight="tableHeight"
        @on-selection-change="checkItem"
        :multiBtn="multiBtn"
        showContextMenu
        :multiColumn="false"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn"></template>
    </ys-page-list>
  </div>
</template>

<script>
import pageListMixins from '@/mixins/pageList';

export default {
  name: 'SingleBuilding',
  mixins: [pageListMixins],
  components: {},
  computed: {
    multiBtn() {
      return []
    },
  },
  data() {
    return {
      params: {
        projectId: ''
      },
      
      bindOrg: false,
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {width: 120, title: '楼号', key: 'number', align: 'center'},
        {width: 120, title: '地上层数', key: 'floor', align: 'center'},
        {width: 120, title: '地下层数', key: 'floorUnderground', align: 'center'},
        {width: 120, title: '高度(m)', key: 'height', align: 'center'},
        {width: 120, title: '地上建面(㎡)', key: 'area', align: 'center'},
        {width: 120, title: '地下建面(㎡)', key: 'areaUnderground', align: 'center'},
        {width: 120, title: '结构形式', key: 'structure', align: 'center'},
        {width: 120, title: '基础类型', key: 'baseType', align: 'center'},
        {width: 120, title: '基础埋深', key: 'baseDepth', align: 'center'},
      ]
    }
  },
  mounted() {
    this.params.projectId = this.mid;
    this.getList()
  }
}
</script>
