<template>
  <div>
    <div id="report" :style="{height:height-160+'px',overflowY: 'auto'}">
      <div id="word">
        <div id="downPdf">
          <ys-paper direction="vertical" v-for="(item,index) in detail.commentList" :key="item.id">
            <ul class="brief" v-if="index===0">
              <li>项目名称：{{ detail.project.iname }}</li>
              <li>订单编号：{{ detail.check.number }}</li>
              <li>风险服务机构：{{ detail.serveOrgName }}</li>
              <li>保险公司：{{ detail.insuranceOrgName }}</li>
              <li>安全巡查人员：{{ detail.check.expertList.map(item => item.iname).join('，') }}</li>
              <li>安全巡查日期：{{ detail.check.serveTime|formatDate('yyyy年MM月dd日') }}</li>
              <li style="width: 100%">本次安全巡查发现安全隐患点数量：{{ detail.commentList.length }}处，具体隐患点及风险等级如下：</li>
            </ul>
            <table border="1" cellspacing="0" cellpadding="0" width="100%" style="border-collapse:collapse;line-height: 25px; text-align: center">
              <tbody>
              <tr>
                <th style="background-color: #DEDEDE" width="5%">序号</th>
                <th style="background-color: #DEDEDE" width="15%">依据条款及风险</th>
                <th style="background-color: #DEDEDE" width="27.5%">隐患图片</th>
                <th style="background-color: #DEDEDE" width="15%">纠正和预防建议</th>
                <th style="background-color: #DEDEDE" width="27.5%">整改反馈图片</th>
              </tr>
              <tr>
                <td rowspan="3">{{ index + 1 }}</td>
                <td>{{ item.remarksRisk }}</td>
                <td align="center">
                  <ys-image :width="item.fileList.length>1?125:250" :height="item.fileList.length>1?125:250" :src="config.baseURL + '/resource/GetFile/'+fileItem.id" v-for="fileItem in item.fileList" :key="fileItem.id"></ys-image>
                </td>
                <td>{{ item.remarksSuggest }}</td>
                <td align="center">
                  <template v-for="replay in item.replayList||[]">
                    <ys-image :width="replay.fileList.length>1?125:250" :height="replay.fileList.length>1?125:250" :src="config.baseURL + '/resource/GetFile/'+fileItem.id" v-for="fileItem in replay.fileList" :key="fileItem.id"></ys-image>
                  </template>
                </td>
              </tr>
              <tr>
                <th style="background-color: #DEDEDE">风险等级</th>
                <td colspan="3" align="left">{{ item.riskGrade|getBaseDataName('风险等级') }}</td>
              </tr>
              <tr>
                <th style="background-color: #DEDEDE">隐患点描述</th>
                <td colspan="3" align="left">{{ item.remarksText }}</td>
              </tr>
              </tbody>
            </table>
          </ys-paper>
        </div>
      </div>
    </div>
    <div class="modalEditBtnArea">
      <Button type="primary" v-print="'#word'">打印预览</Button>
      <Button type="primary" @click="down">下载</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit';
import {getProblemsRebackList} from "@/api/amend";
import {htmlToPdf} from "@/libs/util";
import {getReportInfo} from "@/api/report";
// import JsPDF from 'jspdf'


// import './msyh-normal.js'
// import './msyhbd-bold.js'

export default {
  name: 'rectificationsDown',
  mixins: [edit],
  data() {
    return {
      detail: {},
    }
  },
  mounted() {
    if (this.midData && this.midData.length === 1) {
      getReportInfo({checkId: this.midData[0].checkId}).then((res) => {
        this.detail = res.data;
        this.getReplayList();
      })
    }
  },
  methods: {
    async getReplayList() {
      let all = this.detail.commentList.map((item) => {
        return getProblemsRebackList({checkCommentId: item.id, type: 1})
      })
      await this.$all(all).then((res) => {
        this.detail.commentList.forEach((item, index) => {
          item.replayList = (res[index].data || []).filter((i) => {
            return i.type === 1
          })
        })
      });
      this.$forceUpdate()
    },

    down() {
      this.modal.spinShow = true;
      // let that = this;
      // const doc = new JsPDF('l', 'pt', 'a4'), html = document.getElementById('downPdf') || '';
      // doc.setFont('msyh',)
      //
      // doc.html(html, {
      //   callback: function (doc) {
      //     doc.save('11.pdf');
      //     that.modal.spinShow = false
      //   },
      //   x: 10,
      //   y: 10
      // });
      //
      // return false;
      htmlToPdf(this.detail.project.iname + '的整改建议书', '#downPdf').then(() => {
        this.modal.spinShow = false
      }).catch(() => {
        this.modal.spinShow = false
      })
    }
  }
}
</script>
<style scoped lang="less">
#downPdf {
  width: 841.89pt;
  margin: 0 auto;
}

.brief {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;

  li {
    line-height: 40px;
    padding: 0 10px;
    height: 40px;
    text-align: left;
    width: 50%;
    overflow: hidden;
    list-style: none;
  }
}
</style>
