<template>
  <div class="workStation" :style="{height:height-90+'px',overflowY:'auto',overflowX:'hidden'}">
    <Row :gutter="10">
      <i-col span="16">
        <baseTotal></baseTotal>
        <tasks style="margin-top: 10px;"></tasks>
        <myTasks></myTasks>
      </i-col>
      <i-col span="8">
        <riskItem></riskItem>
        <update v-if="false"></update>
        <articleConsultation style="margin-top: 10px;"></articleConsultation>
      </i-col>
    </Row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Update from "@/views/home/update/index.vue";
import baseTotal from "@/views/home/baseTotal/index.vue";
import Tasks from "@/views/home/tasks/index.vue";
import myTasks from "@/views/home/tasks/myTasks.vue";
import riskItem from "@/views/home/riskItem/index.vue";
import articleConsultation from "@/views/home/articleConsultation";

export default {
  name: "index",
  components: {baseTotal,Update,Tasks, riskItem, myTasks, articleConsultation},
  computed:{
    ...mapGetters(['height'])
  },
}
</script>
<style lang="less" scoped>
.info {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;

  .userName {
    margin-bottom: 12px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }

  .instro {
    color: rgba(0, 0, 0, 0.45);
    line-height: 22px;
  }
}
</style>
