<template>
  <div class="listTemplate">
    <template v-if="tableData.length > 0">
      <div class="item" v-for="(item, index) in tableData" :key="item.id">
        <template v-if="0===item.taskType">
          <div class="content">
            <div class="title">
              <span>[{{ item.typeName }}]</span>
              <span>{{ item.iname }}</span>
              <span class="tag">{{ item.status|getBaseDataName('专项任务状态') }}</span>
            </div>
            <div class="label">
              <span>项目名称:{{ item.projectName }} </span>
              <span>截止日期:{{ item.finishAt|formatDate }} </span>
              <span>计划检查日期:{{ item.serveTime|formatDate }} </span>
            </div>
          </div>
        </template>
        <template v-if="1===item.taskType">
          <div class="content">
            <div class="title">
              <span>[风险整改]</span>
              <span>{{ item.iname }}</span>
              <span class="tag">{{ item.status|getBaseDataName('整改单状态') }}</span>
              <span class="tag">{{ item.checkStatus|getBaseDataName('整改单审核状态') }}</span>
            </div>
            <div class="label">
              <span>项目名称: {{ item.projectName }}</span>
            </div>
          </div>
        </template>
        <template v-if="2===item.taskType">
          <div class="content">
            <div class="title">
              <span>{{ item.title }}</span>
              <span class="tag">{{ item.checkId > 0 ? '已关联' : '未关联' }}</span>
            </div>
            <div class="label">
              <div>
                <span>项目名称: {{ item.projectName }}</span>
                <span>服务时间: {{ item.startAt|formatDate }}至{{ item.finishAt|formatDate }}</span>
              </div>
              <div>
                <span>服务类型: {{ item.typeName }}</span>
                <span v-if="item.subTypeName">服务子类型: {{ item.subTypeName }}</span>
              </div>
            </div>
          </div>
        </template>
        <template v-if="3===item.taskType">
          <div class="content">
            <div class="title">
              <span>[{{ item.typeName }}]</span>
              <span>{{ item.checkName }}</span>
              <span class="tag">{{ item.checkStatus|getBaseDataName('报告审核状态') }}</span>
            </div>
            <div class="label">
              <span>项目名称: {{ item.projectName }}</span>
            </div>
          </div>
        </template>
        <template v-if="4===item.taskType">
          <div class="content">
            <div class="title">
              <span>[{{ item.type|getBaseDataName('整改单类型') }}]</span>
              <span>{{ item.iname }}</span>
              <span class="tag">{{ item.checkStatus|getBaseDataName('整改单审核状态') }}</span>
            </div>
            <div class="label">
              <span>项目名称: {{ item.projectName }}</span>
            </div>
          </div>
        </template>
        <Button type="primary" shape="circle" @click="doTask(index)">去处理
          <Icon type="ios-arrow-forward"/>
        </Button>
      </div>
    </template>
    <ys-empty v-else title="暂无数据"></ys-empty>
    <ys-drawer v-model="flag0">
      <checkDetail :mid="mid"></checkDetail>
    </ys-drawer>
    <ys-drawer v-model="flag1">
      <rectificationsDetail :mid="mid"></rectificationsDetail>
    </ys-drawer>
    <ys-modal v-model="flag2" :width="1000" :title="planTab==='1'?'新增检查任务' : '关联检查任务'">
      <Tabs v-model="planTab" type="card">
        <TabPane label="新增任务" name="1" style="padding-top: 20px;">
          <taskAdd :modalRank="4" :planData="[midData]" :type="planType" :subType="planSubType" v-if="planTab === '1'"></taskAdd>
        </TabPane>
        <TabPane label="关联检查任务" name="2" style="padding-top: 20px;">
          <relationTask :modalRank="4" :modData="[midData]" v-if="planTab === '2'"></relationTask>
        </TabPane>
      </Tabs>
    </ys-modal>
    <ys-drawer v-model="flag3">
      <reportDetail :mid="midData.checkId"></reportDetail>
    </ys-drawer>
    <ys-drawer v-model="flag4">
      <rectificationsDetail :mid="mid"></rectificationsDetail>
    </ys-drawer>
  </div>
</template>

<script>
export default {
  name: 'doSpecial',
  props: {
    tableData: {type: Array, default: () => []},
  },
  components: {
    'checkDetail': () => import('@/views/task/detail'),
    'taskAdd': () => import('@/views/task/edit'),
    'reportDetail': () => import('@/views/report/detail'),
    'rectificationsDetail': () => import('@/views/rectifications/detail'),
    'relationTask': () => import('@/views/plan/relationTask.vue'),
  },
  data() {
    return {
      flag0: false,
      flag1: false,
      flag2: false,
      flag3: false,
      flag4: false,
      mid: '',
      midData: [],
      planTab: '1',
    }
  },
  computed: {
    planType() {
      return this.midData?.type || 2
    },
    planSubType() {
      return this.midData?.subType || null
    },
  },
  mounted() {
  },
  methods: {
    doTask(index) {
      this['flag' + this.tableData[index].taskType] = true;
      this.mid = this.tableData[index].id;
      this.midData = this.tableData[index]
    }
  }
}
</script>

<style scoped lang="less">


</style>
