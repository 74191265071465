<template>
  <div class="home">
    <ys-page-list
        ref="table"
        action="/spot/comment/List"
        :params="params"
        :tableHeight="tableHeight"
        @on-selection-change="checkItem"
        :multiBtn="multiBtn"
        :header="header"
        :footer="footer"
        @on-ok="$emit('on-ok',$event)"
        :multiColumn="multiColumn"
        :detailInner="detailInner"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="风险等级" prop="riskGrade">
          <Select v-model="params.riskGrade" clearable placeholder="风险等级" style="width: 100px;">
            <Option v-for="(item,index) in baseData['风险等级']" :style="{'color':item.color }" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="隐患整改状态" prop="status">
          <Select v-model="params.status" clearable placeholder="整改状态" style="width: 100px;">
            <Option v-for="(item,index) in baseData['隐患状态']" :style="{'color':item.color }" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add" :disabled="midData.length===1&&(midData[0].status!==5||!!midData[0].amendPublishedAt)" v-if="mid">新增</Button>
        </ButtonGroup>
      </template>
      <template slot="detail">
        <Detail :mid="detailID"></Detail>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="1200" :title="(tableSelectItem.length>0?'修改':'新增')+'风险'">
      <Edit @on-ok="getList" :parentID="mid" :midData="midData.length > 0 ? midData : tableSelectItem" :mid="detailID" :isScore="false"></Edit>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList';
import Edit from "@/views/spotCheckTask/detail/problems/edit.vue";
import Detail from "@/views/spotCheckTask/detail/problems/detail.vue";

export default {
  name: 'problems',
  mixins: [pageList],
  props: {
    projectId: {type: [Number, String], default: null},
    categoryId: {type: [Number, String], default: null},
    dictId: {type: [Number, String], default: null},
    itemId: {type: [Number, String], default: null},
    status: {type: [Number], default: null},
    riskGrade: {type: [Number], default: null},
  },
  components: {
    Edit,
    Detail,
  },
  data() {
    return {
      checkFlag: false,
      params: {
        spotId: '',
        riskGrade: '',
        projectId: '',
        categoryId: '',
        dictId: '',
        itemId: '',
        status: '',
        keyword: '',

      },
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {width: 150, title: '隐患整改状态', key: 'status', align: 'center', renderConfig: {type: 'baseData', parentName: '隐患状态'}},
        {minWidth: 150, title: '风险项目', key: 'itemName', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {width: 150, title: '所属检查项', key: 'categoryName', align: 'left'},
        {width: 100, title: '检查专家', key: 'expertName', align: 'center'},
        {width: 250, title: '服务机构', key: 'serveOrgName', align: 'center'},
        {width: 110, title: '发现时间', key: 'serveTime', align: 'center', renderConfig: {type: 'formatDate'}},
        {width: 100, title: '风险等级', key: 'riskGrade', align: 'center', renderConfig: {type: 'baseData', parentName: '风险等级'}},
        {minWidth: 230, title: '风险描述', tooltip: true, key: 'remarksText', align: 'left'},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          hiddenBtnIcon: true,
          children: [
            {
              type: 'detail',
              title: '查看详情',
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              iconColor: '#19be6b',
              disabled: this.tableSelectItem.length !== 1 || (this.midData && this.midData.length === 1 && this.midData[0].status !== 5)
            },
            {
              click: () => this.delItem('/spot/comment/Remove'),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !== 1 || (this.midData && this.midData.length === 1 && this.midData[0].status !== 5)
            },
          ]
        }
      ]
    }
  },
  mounted() {
    this.params.riskGrade = this.riskGrade || '';
    this.params.projectId = this.projectId || this.midData[0].project.id || '';
    this.params.spotId = this.mid || '';
    this.params.categoryId = this.categoryId || '';
    this.params.dictId = this.dictId || '';
    this.params.itemId = this.itemId || '';
    this.params.status = this.status || '';
    this.getList()
  },
  methods: {}
}
</script>
