<template>
  <Card title="我的任务统计" :dis-hover="true" :bordered="false" :style="{'margin-top': '10px', width: '100%'}">
    <template #extra><span>仅显示最近12个月的任务情况</span></template>
    <ys-empty v-if="option.xAxis.data.length === 0 || option.series[0].data.length === 0" title="暂无数据"></ys-empty>
    <div id="myTasks" style="height: 250px;width: 100%"></div>
  </Card>
</template>

<script>
import * as echarts from 'echarts';
import {mapGetters} from 'vuex';
import {workCheckCount} from '@/api/common.js'

export default {
  name: '',
  props: {},
  data() {
    return {
      myChart: null,
      option: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              show: false,
            }
          }
        },
        grid: {
          left: "3%",
          top: "9%",
          right: "0%",
          bottom: "15%",
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            interval: 0,
            rotate: 30
          },
          data: [],
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '完成任务数',
            data: [],
            type: 'line',
            smooth: true,
            label: {
              show: true
            },
            tooltip: {
              show: true
            }
          }
        ]
      },
    }
  },
  computed: {
    ...mapGetters(['height']),
  },
  mounted() {
    workCheckCount().then(res => {
      this.option.xAxis.data = (res.data || []).map(item => item.moon)
      this.option.series[0].data = (res.data || []).map(item => item.value)
      this.setChart();
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
    })
  },
  beforeDestroy() {

  },
  methods: {
    setChart() {
      this.myChart = echarts.init(document.getElementById('myTasks'));
      this.myChart.setOption(this.option);
    }
  }
}
</script>

<style>

</style>