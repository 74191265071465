import {get, post} from 'ys-admin'

/**
 * 抽查任务详情
 * @param {Object} params
 * @returns {Object}
 */
export const spotGetInfo = function (params) {
  return get('/spot/GetInfo', params)
};

/**
 * 确认任务
 * @param {Object} params
 * @returns {Object}
 */
 export const spotConfirm = function (params) {
  return post('/spot/Confirm', params)
};

/**
 * 完成任务
 * @param {Object} params
 * @returns {Object}
 */
 export const spotFinish = function (params) {
  return post('/spot/Finish', params)
};

/**
 * 抽查任务风险项
 * @param {Object} params
 * @returns {Object}
 */
 export const spotDictGetInfo = function (params) {
  return post('/spot/dict/GetInfo', params)
};

/**
 * 抽查任务风险辨别扣分项
 * @param {Object} params
 * @returns {Object}
 */
 export const spotDictDetailList = function (params) {
  return post('/spot/dict/detail/List', params)
};

/**
 * 抽查任务隐患详情
 * @param {Object} params
 * @returns {Object}
 */
 export const spotCommentGetInfo = function (params) {
  return get('/spot/comment/GetInfo', params)
};

/**
 * 抽查任务资料检查资料详情
 * @param {Object} params
 * @returns {Object}
 */
 export const spotDocGetInfo = function (params) {
  return get('/spot/doc/GetInfo', params)
};

/**
 * 抽查任务资料检查资料录入
 * @param {Object} params
 * @returns {Object}
 */
 export const spotDocGetEdit = function (params) {
  return post('/spot/doc/Edit', params)
};

/**
 * 抽查任务资料检查资料内容清单
 * @param {Object} params
 * @returns {Object}
 */
 export const spotDocItemList = function (params) {
  return get('/spot/doc/item/List', params)
};

