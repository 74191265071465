<template>
  <div>
    <ys-page-list
      keyID="AA1EFC0F33DAC1156BF3D9ECDCC3CFB3"
      ref="table"
      action="/spot/List"
      :params="params"
      :tableHeight="tableHeight"
      @on-selection-change="checkItem"
      :multiBtn="multiBtn"
      showContextMenu
      :multiColumn="true"
      :headerColumns="headerColumns"
    >
      <template slot="search">
        <FormItem label="任务状态" prop="status">
          <Select v-model="params.status" :max-tag-count="2" class="selectMultiple" clearable placeholder="任务状态" style="width: 250px;">
            <Option v-for="(item,index) in baseData['抽查任务状态'].filter(item => item.value !== 0)" :value="item.value" :key="index">{{ item.name }}
            </Option>
          </Select>
        </FormItem>
        <FormItem label="打卡时间" prop="daterange">
          <ys-date-picker type="daterange" v-model="params.daterange" placeholder="时间周期"></ys-date-picker>
        </FormItem>
<!--        <FormItem label="服务机构" prop="serviceName">-->
<!--          <Input placeholder="服务机构" clearable readonly style="width: 250px;" v-model="params.serviceName" search enter-button="服务机构" @on-search="serviceShow = true"></Input>-->
<!--        </FormItem>-->
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="请输入项目名称、保单编号" style="width: 200px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="detail">
        <Detail :mid="detailID"></Detail>
      </template>
    </ys-page-list>
    <ys-modal v-model="finishShow" :width="900" :title="'完成抽查'">
      <Finish :mid="detailID" @on-ok="getList"></Finish>
    </ys-modal>
    <ys-modal v-model="problemFlag" :width="1200" :title="'录入隐患'">
      <add-problems :midData="tableSelectItem" :isScore="false" @on-ok="getList"></add-problems>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import Detail from "@/views/spotCheckTask/detail.vue";
import Finish from "@/views/spotCheckTask/finish.vue";
import addProblems from "@/views/spotCheckTask/detail/problems/edit.vue";
import {spotConfirm} from "@/api/spotCheckTask"

export default {
  name: 'spotCheckTask',
  mixins: [pageList],
  props: {},
  components: {
    Detail,
    Finish,
    addProblems,
  },
  data() {
    return {
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {width: 180, title: '任务编号', key: 'number', align: 'left'},
        {minWidth: 180, title: '任务名称', key: 'iname', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {minWidth: 180, title: '项目名', key: 'projectName', align: 'left'},
        {minWidth: 180, title: '服务机构', key: 'serveOrgName', align: 'left'},
        {width: 110, title: '含资料检查', key: 'hasDoc', align: 'center', renderConfig: {type: 'baseData', parentName: '是否'}},
        {width: 170, title: '打卡日期', key: 'signTime', align: 'left', renderConfig: {type: 'formatDate', fmt: 'yyyy-MM-dd hh:mm:ss'}},
        {minWidth: 200, title: '检查专家', key: 'experts', align: 'left'},
        {width: 170, title: '发布日期', key: 'publishAt', align: 'left', renderConfig: {type: 'formatDate', fmt: 'yyyy-MM-dd hh:mm:ss'}},
        {width: 120, title: '计划服务日期', key: 'serveTime', align: 'left', renderConfig: {type: 'formatDate'}},
        {width: 120, title: '完成截止日', key: 'finishAt', align: 'left', renderConfig: {type: 'formatDate'}},
        {minWidth: 180, title: '检查标准', key: 'warehouseName', align: 'left'},
        {width: 70, title: '状态', key: 'status', align: 'left', renderConfig: {type: 'baseData', parentName: '专项任务状态'}},
      ],
      serviceShow: false,
      finishShow: false,
      problemFlag: false,
      params: {
        signStartAt: '',
        signEndAt: '',
        serveOrgId: '',
        serviceName: '',
        daterange: [],
        type: '',
        keyword: '',
        noArea: true,
        isTop: false,
      }
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          hiddenBtnIcon: true,
          children: [
            {
              type: 'detail',
              title: '查看详情',
              targetUrl: '/main/spotCheckTask/detail/' + this.detailID,
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        },
        {
          hiddenBtnIcon: true,
          children: [
            {
              click: () => this.confirmSpotCheckTask(),
              title: '确认',
              icon: 'md-contact',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status !== 2
            },
            {
              click: () => this.problemFlag = true,
              title: '新增风险',
              icon: 'md-add',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status !== 5
            },
            {
              click: () => this.finishShow = true,
              title: '完成抽查',
              icon: 'md-contact',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status !== 5
            },
          ]
        },
      ]
    }
  },
  watch: {
    'params.daterange'() {
      this.params.signStartAt = this.params.daterange[0] ? this.params.daterange[0] + ' ' + '00:00:00' : ''
      this.params.signEndAt = this.params.daterange[1] ? this.params.daterange[1] + ' ' + '23:59:59' : ''
    },
  },
  mounted() {
    this.params.orgId = this.userInfo.org_id;
    this.getList();
  },
  
  methods: {
    confirmSpotCheckTask() {
      this.$Modal.confirm({
        title: '提示',
        content: '确认要开始处理该任务?',
        okText: '确定',
        onOk: () => {
          spotConfirm({id: this.tableSelectItem[0].id}).then((res) => {
            if (res.code === 200) {
              this.$Notice.success({
                title: '操作成功！'
              });
              this.getList();
            }
          });
        },
      });
    },
  }
}
</script>
