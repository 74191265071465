<template>
  <div class="home">
    <ys-page-list
        ref="table"
        action="/checkUser/record/List"
        keyID="3A2261B80BAA79973AC73C3A349F01B5"
        :params="params"
        :tableHeight="tableHeight"
        @on-selection-change="checkItem"
        :multiBtn="multiBtn"
        showContextMenu
        :multiColumn="true"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" style="width: 120px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="detail">
        <Detail :mid="detailID"></Detail>
      </template>
    </ys-page-list>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList';
import Detail from "./detail.vue";

export default {
  name: 'problems',
  mixins: [pageList],
  components: {
    Detail,
  },
  data() {
    return {
      params: {
        keyword: '',
      },
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {minWidth: 130, title: '打卡地址', key: 'address', align: 'left',renderConfig: {type: 'gotoDetail'}},
        {width: 200, title: '打卡时间', key: 'signTime', align: 'center',},
        {minWidth: 220, title: '任务名称', key: 'checkName', align: 'left'},
        {width: 100, title: '任务状态', key: 'checkStatus', align: 'left', renderConfig: {type: 'baseData',  parentName:'专项任务状态'}},
        {minWidth: 220, title: '保险机构', key: 'insName', align: 'left'},
        {minWidth: 130, title: '项目名称', key: 'projectName', align: 'left'},

        // {minWidth: 130, title: '打卡备注', key: 'desc', align: 'left'},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          hiddenBtnIcon: true,
          children: [
            {
              type: 'detail',
              title: '查看详情',
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },

          ]
        },
      ]
    }
  },
  mounted() {
    this.getList()
  }
}
</script>
