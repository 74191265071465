<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="80">
      <FormItem label="所属机构" prop="orgId">
        <Select v-model="form.policyId" placeholder="所属机构" @on-change="orgChange">
          <Option v-for="(item,index) in orgList" :value="item.policyId" :key="index">{{ item.enterpriseName }}-{{ item.projectName||item.number }}</Option>
          <Option :value="0">其他</Option>
        </Select>
      </FormItem>
      <FormItem label="机构名称" prop="orgName" v-if="form.policyId===0">
        <Input v-model="form.orgName" placeholder="机构名称"> </Input>
      </FormItem>
      <FormItem label="姓名" prop="name">
        <Input v-model="form.name" placeholder="姓名" style="width: 30%;"> </Input>
        <Input v-model="form.phone" placeholder="电话" style="width: 70%;"> </Input>
      </FormItem>
      <FormItem label="职位" prop="position">
        <Input v-model="form.position" placeholder="职位"> </Input>
      </FormItem>
      <FormItem label="签到时间" prop="signAt">
        <ys-date-picker type="datetime" style="width: 100%" placeholder="签到时间" v-model="form.signAt"/>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok('/check/member/Add')">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import edit from '@/mixins/edit'
import {eventInfo} from "@/api/train";

export default {
  name: '',
  mixins: [edit],
  data() {
    return {
      orgList: [],
      ruleValidate: {
        name: [
          {required: true, message: '请填写姓名', trigger: 'blur', type: 'string'}
        ],
        orgName: [
          {required: true, message: '请填写机构名称', trigger: 'blur', type: 'string'}
        ],
      },
      form: {
        eventId: '',
        expertId: '',
        phone: '',
        policyId: '',
        position: '',
        orgName: '',
        name: '',
        orgId: '',
        signAt: new Date(),
      },
    }
  },
  mounted() {
    this.form.eventId = this.parentID||this.$router.currentRoute.params.id
    this.getEventInfo();
  },
  methods: {
    getEventInfo() {
      eventInfo({id: this.form.eventId}).then(res => {
        this.orgList = res.data.projects || [];
        this.form.policyId = this.orgList.length > 0 ? this.orgList[0].policyId : '';
        this.orgChange();
      })
    },
    orgChange() {
      const data = this.orgList.find(item => item.id = this.form.policyId);
      this.form.orgName = data ? data.enterpriseName : '';
      this.form.orgId = data ? data.enterpriseId : '';
    }
  }
}
</script>
<style scoped>

</style>
