<template>
  <div class="home">
    <ys-page-list
      ref="table"
      keyLabel="userId"
      :tableHeight="tableHeight"
      :tableLoading="tableLoading"
      @on-selection-change="checkItem"
      :headerColumns="headerColumns"
      :multiColumn="false"
      :multiBtn="multiBtn"
      :searchFun="getDetailList"
      :isPage="false"
      :tableData="expertsList">
    </ys-page-list>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js';
import {spotGetInfo} from "@/api/spotCheckTask";

export default {
  name: '',
  mixins: [pageList],
  components: {},
  data() {
    return {
      expertShow: false,
      tableLoading: false,
      headerColumns: [
        {width: 50, type: 'selection', title: '选择框', align: 'center'},
        {width: 50, type: 'index', title: '序号', align: 'center'},
        {minWidth: 180, title: '专家姓名', key: 'iname', align: 'center'},
        {minWidth: 180, title: '电话', key: 'phone', align: 'center', renderConfig: {type: 'phoneDesensitise'}},
        {minWidth: 100, title: '是否组长', key: 'isLeader', align: 'center', renderConfig: {type: 'baseData', parentName: '是否'}},
        {minWidth: 100, title: '是否签到', key: 'isConfirm', align: 'center', renderConfig: {type: 'baseData', parentName: '是否'}},
        {minWidth: 100, title: '是否确认', key: 'isSign', align: 'center', renderConfig: {type: 'baseData', parentName: '是否'}},
        {minWidth: 180, title: '签到时间', key: 'signTime', align: 'center', renderConfig: {type: 'formatDate', fmt: 'yyyy-MM-dd hh:mm:ss'}},
      ],
      expertsList: [],
    }
  },
  computed: {
    multiBtn() {
      let data = []
      return data;
    }
  },
  watch: {},
  mounted() {
    if (this.mid) {
      this.getDetailList()
    }
  },
  methods: {
    getDetailList() {
      this.expertsList = []
      this.tableLoading = true; // 按项目查看
      spotGetInfo({id: this.mid, random: new Date().getTime()}).then((res) => {
        this.tableLoading = false
        if (res.data.expertList || []) {
          this.expertsList = (res.data.expertList || []).map(item => {
            return item
          })
        }
      }).catch(() => {
        this.tableLoading = false
      })
    }
  }

}
</script>
